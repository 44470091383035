import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router';
import { ELocalStorageKey, ERouterName, EUserType } from '/@/types/index';
import CreatePlan from '/@/components/task/CreatePlan.vue';
import WaylinePanel from '/@/pages/page-web/projects/wayline.vue';
import waylineFuya from '/@/pages/page-web/projects/waylineFuya.vue';
import DockPanel from '/@/pages/page-web/projects/dock.vue';
import LiveAgora from '/@/components/livestream-agora.vue';
import LiveOthers from '/@/components/livestream-others.vue';
import { getIdByName, getTanantById, getUserInfo, login2 } from '../api/manage';
import { aesDecrypt } from '../utils/ase';
import { login, userInfoData } from '../api/service/fuya';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/' + ERouterName.PROJECT,
	},
	// 首页
	{
		path: '/' + ERouterName.PROJECT,
		name: ERouterName.PROJECT,
		component: () => import('/@/pages/page-web/index.vue'),
	},
	{
		path: '/video',
		name: 'video',
		component: () => import('/@/pages/page-web/video.vue'),
	},
	{
		path: '/drone',
		name: 'droneCard',
		component: () => import('/@/pages/drone/card.vue'),
	},
	{
		path: '/layers',
		name: 'layers',
		component: () => import('/@/pages/layers/index.vue'),
	},
	{
		path: '/lmap',
		name: 'lmap',
		component: () => import('/@/components/l-map/map.vue'),
	},
	{
		path: '/rtcplay',
		name: 'rtcplay',
		component: () => import('/@/components/live/jessibuca.vue'),
		props: true,
	},
	{
		path: '/videoCenter',
		name: 'videoCenter',
		component: () => import('/@/pages/drone/video-center.vue'),
	},
	{
		path: '/dockDroneVideo',
		name: 'dockDroneVideo',
		component: () => import('/@/pages/drone/dock-drone-video.vue'),
	},
	{
		path: '/directorChannel',
		name: 'directorChannel',
		component: () => import('/@/pages/drone/director-channel.vue'),
	},
	{
		path: '/directorChannelControl',
		name: 'directorChannelControl',
		component: () => import('/@/pages/drone/director-channel-control.vue'),
	},
	{
		path: '/directorChannelControlContainer',
		name: 'directorChannelControlContainer',
		component: () => import('/@/pages/drone/director-channel-control-container.vue'),
	},
	{
		path: '/unmannedDevice',
		name: 'unmannedDevice',
		component: () => import('/@/pages/unmanned-device/index.vue'),
		children: [
			{
				path: 'unmannedShip',
				name: 'unmannedShip',
				component: () => import('/@/pages/unmanned-device/components/livestream-agora-ship.vue'),
			},
			{
				path: '/unmannedDevice/RTMPLive/:rtmpUrl',
				name: 'RTMPLive',
				component: () => import('/@/components/livestream-byrtmpurl-unstyle.vue'),
				props: true,
			},
		],
	},
	{
		path: '/RTMPLive/videoCenter',
		name: 'RTMPLiveVideoCenter',
		component: () => import('/@/pages/RTMPLive/video-center.vue'),
	},
	{
		path: '/sandTableType',
		name: 'sandTableType',
		component: () => import('/@/pages/electronic-sand-table/sand-table-type/index.vue'),
	},
	{
		path: '/sandTable',
		name: 'sandTable',
		component: () => import('/@/pages/electronic-sand-table/sand-table/index.vue'),
	},
	{
		path: '/singleSoldier/videoCenter',
		name: 'singleSoldierVideoCenter',
		component: () => import('/@/pages/singleSoldier/video-center.vue'),
	},
	{
		path: '/drone/cctv',
		name: 'droneCctv',
		component: () => import('/@/pages/drone/video-play.vue'),
	},
	// members, devices
	{
		path: '/' + ERouterName.HOME,
		name: ERouterName.HOME,
		component: () => import('/@/pages/page-web/home.vue'),
		children: [],
	},
	{
		path: '/psdk/:gatewaySn',
		name: 'psdk',
		component: () => import('/@/components/megaphone/list.vue'),
		children: [],
		props: true,
	},
	{
		path: '/smartDog',
		name: 'smartDog',
		component: () => import('/@/pages/unmanned-device/components/smartdog-control.vue'),
		props: true,
	},
	{
		path: '/psdkControl',
		name: 'psdkControl',
		component: () => import('/@/pages/unmanned-device/components/psdkcontrol/psdk-control.vue'),
		props: true,
	},
	// createWaypointData
	{
		path: '/' + ERouterName.CREATEWAYPOINTDATA,
		name: ERouterName.CREATEWAYPOINTDATA,
		component: () => import('/@/pages/page-web/projects/createWaypointData.vue'),
		children: [],
	},
	// 任务航线
	{
		path: '/' + ERouterName.TASKTRACK,
		name: ERouterName.TASKTRACK,
		component: () => import('/@/pages/page-web/projects/task_track.vue'),
		children: [],
	},
	{
		path: '/' + ERouterName.DAPING,
		component: () => import('/@/pages/page-web/daping/index.vue'),
	},
	// workspace
	{
		path: '/' + ERouterName.WORKSPACE,
		name: ERouterName.WORKSPACE,
		component: () => import('/@/pages/page-web/projects/workspace.vue'),
		redirect: '/' + ERouterName.TSA,
		children: [
			{
				path: '/' + ERouterName.LIVESTREAM,
				name: ERouterName.LIVESTREAM,
				component: () => import('/@/pages/page-web/projects/livestream.vue'),
				children: [
					{
						path: ERouterName.LIVING,
						name: ERouterName.LIVING,
						components: {
							LiveAgora,
							LiveOthers,
						},
					},
				],
			},
			{
				path: '/' + ERouterName.TSA,
				name: ERouterName.TSA,
				component: () => import('/@/pages/page-web/projects/tsa.vue'),
			},
			{
				path: '/' + ERouterName.TSA3,
				name: ERouterName.TSA3,
				component: () => import('/@/pages/page-web/projects/tsa3.vue'),
			},
			{
				path: '/' + ERouterName.TASKDATA,
				name: ERouterName.TASKDATA,
				component: () => import('/@/pages/page-web/projects/taskData.vue'),
			},
			{
				path: '/' + ERouterName.TASKDATA1,
				name: ERouterName.TASKDATA1,
				component: () => import('/@/pages/page-web/projects/taskDataDetail.vue'),
			},

			{
				path: '/' + ERouterName.REALTIMELISTENING,
				name: ERouterName.REALTIMELISTENING,
				component: () => import('/@/pages/page-web/projects/realTimeListening.vue'),
			},
			{
				path: '/' + ERouterName.LAYER,
				name: ERouterName.LAYER,
				component: () => import('/@/pages/page-web/projects/layer.vue'),
			},
			{
				path: '/' + ERouterName.AI,
				name: ERouterName.AI,
				component: () => import('/@/pages/page-web/projects/AItraining.vue'),
			},
			{
				path: '/' + ERouterName.AI_CONFIG,
				name: ERouterName.AI_CONFIG,
				component: () => import('/@/pages/page-web/projects/AiConfig.vue'),
			},
			{
				path: '/' + ERouterName.FULL_VIEW_NAVIGATION,
				name: ERouterName.FULL_VIEW_NAVIGATION,
				component: () => import('/@/pages/page-web/projects/fullViewNavigation.vue'),
			},
			{
				path: '/' + ERouterName.MEDIA,
				name: ERouterName.MEDIA,
				component: () => import('/@/pages/page-web/projects/media.vue'),
			},
			{
				path: '/' + ERouterName.WAYLINE,
				name: ERouterName.WAYLINE,
				component: () => import('/@/pages/page-web/projects/wayline.vue'),
			},
			{
				path: '/' + ERouterName.WAYLINE3D,
				name: ERouterName.WAYLINE3D,
				component: () => import('/@/pages/page-web/projects/wayline3d.vue'),
			},
			{
				path: '/' + ERouterName.TASK,
				name: ERouterName.TASK,
				component: () => import('/@/pages/page-web/projects/task.vue'),
				children: [
					{
						path: ERouterName.CREATE_PLAN,
						name: ERouterName.CREATE_PLAN,
						component: CreatePlan,
						children: [
							{
								path: ERouterName.SELECT_PLAN,
								name: ERouterName.SELECT_PLAN,
								components: {
									WaylinePanel,
									waylineFuya,
									DockPanel,
								},
							},
						],
					},
				],
			},
			{
				path: '/' + ERouterName.MEMBERS,
				name: ERouterName.MEMBERS,
				component: () => import('/@/pages/page-web/projects/members.vue'),
			},
			{
				path: '/' + ERouterName.DEVICES,
				name: ERouterName.DEVICES,
				component: () => import('/@/pages/page-web/projects/devices.vue'),
			},
			{
				path: '/' + ERouterName.FIRMWARES,
				name: ERouterName.FIRMWARES,
				component: () => import('../pages/page-web/projects/Firmwares.vue'),
			},
		],
	},
	// upload
	{
		path: '/CameraControlPanel/:sn/:payload',
		name: 'cameraControlPanel',
		component: () => import('../components/v3.0/g-map/CameraControlPanel.vue'),
		props: true,
	},
	{
		path: '/ai/config',
		name: 'ai/config',
		component: () => import('/@/pages/ai/AiConfig.vue'),
	},
	{
		path: '/ai/edit-img/:waylineId/:id',
		name: 'ai/edit-img',
		component: () => import('/@/pages/ai/edit-img.vue'),
		props: true,
	},
	{
		path: '/ai/clue',
		name: 'ai/clue',
		component: () => import('/@/pages/ai/clue.vue'),
	},
	{
		path: '/' + ERouterName.UPLOAD,
		name: ERouterName.UPLOAD,
		component: () => import('../pages/page-upload/upload-master.vue'),
	},
	{
		path: '/' + ERouterName.UPLOADFILE,
		name: ERouterName.UPLOADFILE,
		component: () => import('../pages/page-upload/upload-file.vue'),
	},
	{
		path: '/' + ERouterName.MODELLIBRARY,
		name: ERouterName.MODELLIBRARY,
		component: () => import('../pages/page-upload/model-library.vue'),
	},
	// pilot
	{
		path: '/' + ERouterName.PILOT,
		name: ERouterName.PILOT,
		component: () => import('/@/pages/page-pilot/pilot-index.vue'),
	},
	{
		path: '/' + ERouterName.APP_CREATE_CLUE,
		name: ERouterName.APP_CREATE_CLUE,
		component: () => import('/@/pages/page-pilot/task/createClue.vue'),
	},
	{
		path: '/' + ERouterName.APP_TASK_LIST,
		name: ERouterName.APP_TASK_LIST,
		component: () => import('/@/pages/page-pilot/task/tasklist.vue'),
	},
	{
		path: '/' + ERouterName.APP_CREATE_TASK,
		name: ERouterName.APP_CREATE_TASK,
		component: () => import('/@/pages/page-pilot/task/createTask.vue'),
	},
	//app任务详情
	{
		path: '/' + ERouterName.APP_TASK_DETAIL,
		name: ERouterName.APP_TASK_DETAIL,
		component: () => import('/@/pages/page-pilot/task/taskDetail.vue'),
	},
	{
		path: '/' + ERouterName.PILOT_HOME,
		component: () => import('/@/pages/page-pilot/pilot-home.vue'),
	},
	{
		path: '/' + ERouterName.PILOT_MEDIA,
		component: () => import('/@/pages/page-pilot/pilot-media.vue'),
	},
	{
		path: '/' + ERouterName.PILOT_LIVESHARE,
		component: () => import('/@/pages/page-pilot/pilot-liveshare.vue'),
	},
	{
		path: '/' + ERouterName.PILOT_BIND,
		component: () => import('/@/pages/page-pilot/pilot-bind.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	// history: createWebHashHistory(),
	routes,
});

export const getCurrInfo = async (tenant_id: string) => {
	let tenantRes = (await getTanantById(tenant_id)) as any;
	// debugger
	console.info('tenantRes', tenantRes);
	if (tenantRes.status == 200 && tenantRes.data.code == 0) {
		let datas = tenantRes.data?.data;
		let tenantData = JSON.stringify(tenantRes.data?.data);
		localStorage.setItem('tenantData', tenantData);
		localStorage.setItem('map_center', `${datas.lng},${datas.lat}`);
	}
};

export const getUserData = async () => {
	let result = await getUserInfo();
	console.info('user', result);
	if (result.data) {
		localStorage.setItem(ELocalStorageKey.Username, result.data.username);
		localStorage.setItem(ELocalStorageKey.User, JSON.stringify(result.data));
		localStorage.setItem(ELocalStorageKey.UserId, result.data.user_id);
	}
};

router.beforeEach(async (to, from, next) => {
	console.info('to', to);

	if (to.path == '/display') {
		// let { u } = to.query as { u: string };
		let u = decodeURIComponent((to.query as { u: string }).u);
		console.log('u', u);

		if (u) {
			let userData = aesDecrypt(u);
			console.info('userData', userData);
			if (userData) {
				let userJson = JSON.parse(userData) as {
					username: string;
					password: string;
					tenant_id: string;
					lng: string;
					lat: string;
				};
				if ('lng' in userJson && 'lat' in userJson) {
					localStorage.setItem('map_center', `${userJson.lng},${userJson.lat}`);
				}
				const result = await login2(
					{
						username: userJson.username,
						password: userJson.password,
					},
					{
						'tenant-id': userJson.tenant_id,
					}
				);
				if (result.code === 0) {
					localStorage.setItem('tenantId', userJson.tenant_id);
					localStorage.setItem(ELocalStorageKey.Token, result.data.extraData?.access_token);
					localStorage.setItem(ELocalStorageKey.TokenNew, result.data.accessToken);
					localStorage.setItem(ELocalStorageKey.WorkspaceId, result.data.extraData.workspace_id);
					localStorage.setItem(ELocalStorageKey.Username, result.data.extraData.username);
					localStorage.setItem(ELocalStorageKey.User, JSON.stringify(result.data.extraData));
					localStorage.setItem(ELocalStorageKey.UserId, result.data.userId);
					localStorage.setItem(ELocalStorageKey.Flag, EUserType.Web.toString());
				}
				await getCurrInfo(userJson.tenant_id);
			}
		}
	} else {
		if (to.query && to.query.tenantId && to.query.token && to.query.ACCESS_TOKEN && to.query.workspace_id && to.query.lng && to.query.lat) {
			let { tenantId, token, ACCESS_TOKEN, workspace_id, lng, lat } = to.query as {
				lng: string;
				lat: string;
				workspace_id: string;
				tenantId: string;
				token: string;
				ACCESS_TOKEN: string;
			};
			if ('lng' in to.query && 'lat' in to.query) {
				localStorage.setItem('map_center', `${to.query.lng},${to.query.lat}`);
			}
			localStorage.setItem(ELocalStorageKey.Token, token);
			localStorage.setItem(ELocalStorageKey.TokenNew, ACCESS_TOKEN);
			localStorage.setItem(ELocalStorageKey.WorkspaceId, workspace_id);
			localStorage.setItem('tenantId', tenantId);
			localStorage.setItem(ELocalStorageKey.Flag, EUserType.Web.toString());
			await getUserData();
			await getCurrInfo(tenantId);

			// try {

			// } catch (error) {

			// }
		} else {
			// await login()
			//  await userInfoData()
		}
	}
	next();
});

export default router;
// https://uav3.web.redfly.tech/wayline?iscloseSidebar=true&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ3b3Jrc3BhY2VfaWQiOiJlM2RlYTBmNS0zN2YyLTRkNzktYWU1OC00OTBhZjMyMjgwNjkiLCJ0ZW5hbnRfaWQiOiIyMDMiLCJzdWIiOiJqc3lpOTk5OSIsInVzZXJfdHlwZSI6IjEiLCJuYmYiOjE3MDQ1MjIwNTAsImxvZyI6IkxvZ2dlcltjb20uZGppLnNhbXBsZS5jb21tb24ubW9kZWwuQ3VzdG9tQ2xhaW1dIiwiaXNzIjoiZmx5cmVkIiwidnhfbWluaV9wcm9ncmFtX3R5cGVzIjoiMTAwMSIsImlkIjoiMzM1MSIsImV4cCI6MTc5MDkyMjA1MCwiaWF0IjoxNzA0NTIyMDUwLCJ1c2VybmFtZSI6ImxzQWRtaW4ifQ.IKb9Yw_3X_sRha1tDfx8I2NlLeZOikTY28s6inwtENI
// http://localhost:8080/tsa3?iscloseSidebar=true&token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ3b3Jrc3BhY2VfaWQiOiJlM2RlYTBmNS0zN2YyLTRkNzktYWU1OC00OTBhZjMyMjgwNjkiLCJ0ZW5hbnRfaWQiOiIyMDMiLCJzdWIiOiJqc3lpOTk5OSIsInVzZXJfdHlwZSI6IjEiLCJuYmYiOjE3MDQ1MTg4NzcsImxvZyI6IkxvZ2dlcltjb20uZGppLnNhbXBsZS5jb21tb24ubW9kZWwuQ3VzdG9tQ2xhaW1dIiwiaXNzIjoiZmx5cmVkIiwidnhfbWluaV9wcm9ncmFtX3R5cGVzIjoiMTAwMSIsImlkIjoiMzM1MSIsImV4cCI6MTc5MDkxODg3NywiaWF0IjoxNzA0NTE4ODc3LCJ1c2VybmFtZSI6ImxzQWRtaW4ifQ.1d3CDl5JztGuLQhJz4gc5a9k9HnfmZlDXSxI01yLX8s&lng=118.82915&lat=32.002907&ACCESS_TOKEN=4fe598323e5c4294bedd8543d2fa288d&workspace_id=e3dea0f5-37f2-4d79-ae58-490af3228069&tenantId=203

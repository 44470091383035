

<template>
	<a-form
		:model="createWaypointLineData"
		ref="creataWaypointDataRef"
		name="createWaypointLine"
		:label-col="{ span: 24 }"
		:wrapper-col="{ span: 24 }"
		autocomplete="off"
	>
		<a-form-item label="航线类型">
			<a-radio-group v-model:value="createWaypointLineData.waypointType" style="width: 100%">
				<div style="width: 100%; display: flex; flex-direction: row; justify-content: space-around; align-items: center;flex-wrap: wrap;">
					<a-radio-button
						v-for="(item, index) of selectWaypointTypeData"
						:key="index"
						:disabled="item.disabled || false"
						:value="item.value"
						class="waypointType"
					>
						<div class="waypointType_content" :class="item.disabled?'disabled':''">
							<div class="flex_row_center">
                <i :class="item.icon"></i>
							</div>
							<div class="flex_row_center">
								{{ item.label }}
							</div>

						</div>
            <div v-if="item.disabled" class="not-open-label" data-v-0933466a="">暂未开放</div>
					</a-radio-button>
				</div>
			</a-radio-group>
		</a-form-item>
    <a-form-item style="z-index: 1052" label="选择飞行器" class="waypoint_drone_select">
			
      <a-radio-group v-model:value="createWaypointLineData.selectdrone" style="width: 100%">
				<div style="width: 100%; display: flex; flex-direction: row; justify-content: space-around; align-items: center;flex-wrap: wrap;">
					<a-radio-button
						v-for="(item, index) of selectDroneData"
						:key="index"
						:disabled="item.disabled || false"
						:value="item.value"
						class="waypoint_drone_select_option"
					>
						<div class="waypointType_content" :class="item.disabled?'disabled':''">
							<div class="flex_row_center">
								{{ item.label }}
							</div>
						</div>
					</a-radio-button>
				</div>
			</a-radio-group>


		</a-form-item>

    <a-form-item  label="选择型号">
      <a-radio-group v-model:value="createWaypointLineData.camera" style="width: 100%">
				<div style="width: 100%; display: flex; flex-direction: row; justify-content: flex-start; align-items: center;flex-wrap: wrap;">
					<a-radio-button
						v-for="(item, index) of getCameraList"
						:key="index"
						:disabled="item.disabled || false"
						:value="item.value"
						class="waypoint_drone_select_option m-8"
					>
						<div class="waypointType_content" :class="item.disabled?'disabled':''">
							<div class="flex_row_center">
								{{ item.label }}
							</div>
						</div>
					</a-radio-button>
				</div>
			</a-radio-group>
		
		</a-form-item>

		<a-form-item label="航线名称" value="name" :rules="[{ require: true, message: '请输入航线名称' }]">
			<a-input style="background-color: #101010; color: #fff; border-color: #4f4f4f" v-model:value="createWaypointLineData.name"></a-input>
		</a-form-item>
		<a-form-item label="选择航线对应的机场" class="waypoint_dock_select">
			<a-select dropdownClassName="waypoint_drone_select_op" v-model:value="createWaypointLineData.selectDock" style="z-index: 2000">
				<a-select-option class="waypoint_drone_select_option" v-for="(item, index) of dockerLiseSelect" :key="index" :value="item.value">
					{{ item.label }}
				</a-select-option>
			</a-select>
		</a-form-item>
		<a-form-item label="ai类型(可不选)"  class="waypoint_dock_select">
			<a-select dropdownClassName="waypoint_drone_select_op" v-model:value="createWaypointLineData.selectaiType" style="z-index: 2000">
				<a-select-option class="waypoint_drone_select_option" v-for="(item, index) of lineType" :key="index" :value="item.value">
					{{ item.label }}
				</a-select-option>
			</a-select>
			<!-- <a-switch 
				v-model:checked="checked1" 
				@change="selectAi"
				checked-children="开" 
				un-checked-children="关" 
			/> -->


		</a-form-item>
		

		
		<!-- <hr /> -->
	</a-form>
</template>

<script lang="ts" setup>
  import { ref, reactive, defineProps, watch, onMounted, computed } from 'vue';
  import createWaypointDrone from '/@/assets/image/createWaypointDrone.png';
  import { MinusCircleOutlined } from '@ant-design/icons-vue';
  import { getRoot, getApp } from '/@/root';
  import { ELocalStorageKey, ERouterName } from '/@/types';
  import { Domain, getBindingDevices, getDevicesByDomain, getDeviceTopo, saveWayline, saveWaylineDetail } from '/@/api/manage';
  import { message } from 'ant-design-vue';
  import { DEVICE_MODEL_KEY } from '/@/types/device';
  // import "/@/assets/icons/dj.svg";
  import { useMyStore } from '/@/store';
	import { getAiLiveData } from '/@/api/ai';
  import moment from 'moment';
  const store = useMyStore();
  const root = getRoot();

  let yuntaiRef = ref(null);
  let yuntaiDialogRef = ref(null);

  let tenantid = localStorage.getItem('tenantId');

  const emit = defineEmits(['clone', 'confirm','updateStatus']);
  let props = defineProps<{
  	visible?: boolean;
  }>();

  interface CreateWayPointLineData {
  	name: string;
  	selectdrone: string;
  	camera: string;
  	waypointType: string;
  	selectDock: string;
		selectaiType:string
		lineType:{
			[key:string]:string
		}
  }
  const creataWaypointDataRef = ref(null);

  watch(
  	() => props.visible,
  	(val) => {
  		waypointDialogVisible.value = val || false;
  	}
  );

	const lineType = ref<{
					label:string
					value:string
				}[]>([]);
	const lineTypeData = ref<string>("");

	let getListDatas = async () => {
		let aiLiveData: any = await getAiLiveData();
		if (aiLiveData.status == '200') {
			const aiLive = aiLiveData.data as {

				code:string
				data:{
					label:string
					value:string
				}[]

			};
			if (aiLive.code == '0') {
				console.info('aiLive', aiLive);
				// {label:"",value:""},
				lineType.value = [...aiLive.data];
				for(let linekey of lineType.value){
					createWaypointLineData.lineType[linekey.value] = linekey.label

				}
				lineTypeData.value = aiLive.data[0].value;
			}
		}
	};

  const loading = ref(false);
  const waypointDialogVisible = ref(false);
  const createWaypointLineData = reactive<CreateWayPointLineData>({
  	name: '新建航线', // 航线名称
  	selectdrone: '', // 飞机数据
  	camera: '',
  	waypointType: '0',
  	selectDock: '',
		selectaiType:"",
		lineType:{}
  });

  let cancel = (e: any) => {
  	waypointDialogVisible.value = false;
  	emit('clone', e);
  };

  const selectDrone = async (e) => {
  	console.info('selectDrone', e);
  	let workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId) || '';
  	if (!selectCameraData.value[e]) {
  		let getDevices = await getDevicesByDomain(Domain['sub-device'], workspaceId, e);
  		console.info('getDevices', getDevices);
  		if (getDevices.code == 0) {
  			// if(!selectCameraData.value[createWaypointLineData.selectdrone] ){
  			let deviceData = getDevices.data[0].payload.map((ele) => {
  				return {
  					label: ele.payload_name,
  					value: ele.payload_sn,
  				};
  			});

  			console.info('deviceData', deviceData);
  			selectCameraData.value[createWaypointLineData.selectdrone] = deviceData;
  			// }
  			createWaypointLineData.camera = deviceData[0].value;
  		}
  	}

  	console.info('selectCameraData', selectCameraData.value);
  };

  type DEVICE_MODEL_KEY_DATA = typeof DEVICE_MODEL_KEY;

  // type DroneType = keyof DEVICE_MODEL_KEY_DATA

  // let droneArray:DroneType[] = ["M30","M30T","M3E","M3T","M300","M350"]

  let droneList = [
  	{
  		label: '经纬 M30 系列',
  		value: '1',
      disabled:false,
  	},
  	{
  		label: 'Mavic 3 行业系列',
  		value: '2',
      disabled:false,

  	},
  	{
  		label: 'Matrice 3D 系列',
  		value: '3',
      disabled:false,

  	},
  ];



  const selectWaypointTypeData = [
  	{
  		label: '航点航线',
  		icon: 'iconfont icon-cw-wayline-point',
  		value: '0',
  	},
  	{
  		label: '面状航线',
  		image: 'http://',
  		disabled: true,
  		icon: 'iconfont icon-cw-wayline-point1',
  		value: '1',
  	},
  	{
  		label: '带状航线',
  		disabled: true,
  		icon: 'iconfont icon-cw-wayline-band',
  		value: '2',
  	},
    {
  		label: '斜面航线',
  		disabled: true,
  		icon: 'iconfont icon-cw-wayline-facade',
  		value: '3',
  	},
    {
  		label: '几何体航线',
  		disabled: true,
  		icon: 'iconfont icon-cw-wayline-solid',
  		value: '4',
  	},
    {
  		label: '环绕航线',
  		disabled: true,
  		icon: 'iconfont icon-cw-wayline-solid',
  		value: '5',
  	},
  ];


  let cameraList: any = ref({
  	'1': [
  		{
  			label: '经纬 M30',
  			value: '1',
  		},
  		{
  			label: '经纬 M30T',
  			value: '2',
  		},
  	],
  	'2': [
  		{
  			label: 'Mavic 3E',
  			value: '3',
  		},
  		{
  			label: 'Mavic 3T',
  			value: '4',
  		},
  	],
    '3': [
  		{
  			label: 'Matrice 3D',
  			value: '5',
  		},
  		{
  			label: 'Matrice 3TD',
  			value: '6',
  		},
  	],
  });

  let getCameraList = computed(() => {
  	console.info('createWaypointLineData.selectdrone', createWaypointLineData.selectdrone);
  	if (createWaypointLineData.selectdrone) {
  		let camers = cameraList.value[createWaypointLineData.selectdrone];
  		createWaypointLineData.camera = camers[0].value;
  		return camers;
  	} else {
  		return '';
  	}
  });
  /**
   * cameraList[createWaypointLineData.selectdrone]
   */

  interface DockerList {
  	value: string;
  	label: string;
  }
  const dockerLiseSelect = ref<DockerList[]>();


	type Aichecked  = boolean
	const selectAi = () => {
		// checked1.value = !checked1.value
	}

  const selectDockListData = async () => {
  	const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!;

  	let deviceList = await getDeviceTopo(workspaceId);

  	console.info('deviceList', deviceList);

  	if (deviceList.code == 0) {
  		let dockList = (deviceList.data as any)
  			.filter((ele: any) => {
  				return ele.domain == 3 && ele.status;
  			})
  			.map((eles: any) => {
  				return {
  					label: eles.nickname,
  					value: eles.device_sn,
  				};
  			});
  		dockerLiseSelect.value = dockList;
  	}
  };

  onMounted(async () => {
  	selectDroneData.value = droneList;
  	createWaypointLineData.selectdrone = droneList[0].value;
  	// selectCameraData.value = cameraList[createWaypointLineData.selectdrone]
  	createWaypointLineData.camera = cameraList.value[createWaypointLineData.selectdrone][0].value;
  	// }
  	selectDockListData();
		getListDatas()
  });

  let droneOrPayload: any = {
  	'1': {
  		drone: DEVICE_MODEL_KEY['M30'],
  		camera: DEVICE_MODEL_KEY['M30Camera'],
  	},
  	'2': {
  		drone: DEVICE_MODEL_KEY['M30T'],
  		camera: DEVICE_MODEL_KEY['M30TCamera'],
  	},
  	'3': {
  		drone: DEVICE_MODEL_KEY['M3E'],
  		camera: DEVICE_MODEL_KEY['M3ECamera'],
  	},
  	'4': {
  		drone: DEVICE_MODEL_KEY['M3T'],
  		camera: DEVICE_MODEL_KEY['M30TCamera'],
  	},
		'5': {
  		drone: DEVICE_MODEL_KEY['M3D'],
  		camera: DEVICE_MODEL_KEY['M3DCamera'],
  	},
  	'6': {
  		drone: DEVICE_MODEL_KEY['M3TD'],
  		camera: DEVICE_MODEL_KEY['M3TDCamera'],
  	},
  };

  let handleCancel = () => {
  	waypointDialogVisible.value = false;
  };
  interface UserInfo {
  	access_token?: string;
  	mqtt_addr?: string;
  	mqtt_password?: string;
  	mqtt_username?: string;
  	user_id?: string;
  	user_type?: number;
  	username?: string;
  	workspace_id?: string;
  }

  let docker = computed(() => store.state.positionData.dock);

  // 修改当前的数据
  const handleOk = async () => {
  	const username = localStorage.getItem(ELocalStorageKey.Username);
  	const workspace_id = localStorage.getItem(ELocalStorageKey.WorkspaceId);

  	let data: any = {
  		name: createWaypointLineData.name,
  		template: createWaypointLineData.waypointType,
  		username: username,
  		workSpaceId: workspace_id,
  	};
  	data.drone = droneOrPayload[createWaypointLineData.camera].drone;
  	data.payload = droneOrPayload[createWaypointLineData.camera].camera;
  	data.dockSn = createWaypointLineData.selectDock;

  	// // 发送请求
  	if (createWaypointLineData.selectDock == '') {
  		// return message.error('机场sn不能为空');
      createWaypointLineData.selectDock = '1'

  	}
  	let wayline = await saveWayline(data);
  	if (wayline.code == 0) {
  		let userInfoString = localStorage.getItem('user');
  		let userInfo: UserInfo = {};
  		if (userInfoString && Object.prototype.toString.call(userInfoString) == '[object String]') {
  			userInfo = JSON.parse(userInfoString);
  		}

  		let waylineDataByKey = {
  			template: {
  				Document: {
  					createTime: moment().valueOf(),
  					author: userInfo.username,
  					missionConfig: {
  						finishAction: 'goHome',
  						takeOffSecurityHeight: 20,
  						globalTransitionalSpeed: 20,
  						globalRTHHeight: '100',
  						exitOnRCLost: 'executeLostAction',
  						executeRCLostAction: 'goBack',
  						droneInfo: {
  							droneSubEnumValue: '1', // 飞机挂载
  							droneEnumValue: '67',
  						},
  						flyToWaylineMode: 'safely',
  						payloadInfo: {
  							payloadEnumValue: '53',
  							payloadPositionIndex: '0',
  							payloadSubEnumValue: '0',
  						},
  						// takeOffRefPoint: [takeOffRefPoint.lon, takeOffRefPoint.lat], // 起飞点
  						takeOffRefPointAGLHeight: 0,
  					},
  					updateTime: moment().valueOf(),
  					Folder: {
  						duration: 0,
  						distance: 0,
  						waylineCoordinateSysParam: {
  							coordinateMode: 'WGS84',
  							heightMode: 'relativeToStartPoint',
  						},
  						payloadParam: {
  							imageFormat: 'wide,zoom,ir',
  							meteringMode: 'average',
  							returnMode: 'singleReturnFirst',
  							focusMode: 'firstPoint',
  							samplingRate: '240000',
  							payloadPositionIndex: '0',
  							scanningMode: 'repetitive',
  						},
  						globalWaypointHeadingParam: {
  							waypointPoiPoint: '0.000000,0.000000,0.000000',
  							waypointHeadingPoiIndex: '0',
  							waypointHeadingPathMode: 'followBadArc',
  							waypointHeadingAngle: '0',
  							waypointHeadingMode: 'followWayline',
  						},

  						templateType: 'waypoint', //预定义模板类型
  						globalWaypointTurnMode: 'coordinateTurn',
  						globalUseStraightLine: '1',
  						globalHeight: 50,
  						caliFlightEnable: '0',
  						templateId: '0',
  						autoFlightSpeed: 20,
  						gimbalPitchMode: 'manual',
  						Placemark: [],
  					},
  				},
  			},
  		};

  		await saveWaylineDetail(wayline.data, workspace_id, waylineDataByKey, tenantid);

  		let getWaypointCenter = localStorage.getItem('WaypointCenter');
  		if (!getWaypointCenter) {
  			localStorage.setItem('WaypointCenter', JSON.stringify(docker.value));
  		}

  		// 跳转页面
  		// root.$router.push({
  		//   name:ERouterName.CREATEWAYPOINTDATA,
  		//   query:{
  		//     id:wayline.data
  		//   }
  		// })
  		root.$router
  			.replace({
  				name: ERouterName.CREATEWAYPOINTDATA,
  				query: {
  					id: wayline.data,
  					baseUrl: root.$route.fullPath,
  				},
  			})
  			.then(() => {
  				location.reload();
  			});

  		message.success('航线创建成功');
  	} else {
  		return message.error(wayline.message);
  	}
  };

  let yuntaiSelectStatus = ref(false);
  const yuntaiClick = () => {
  	// 打开下拉框
  	yuntaiSelectStatus.value = true;
  	// 选择的飞机
  	let droneId = createWaypointLineData.selectdrone;
  };

  // window.onclick = function(){

  // }

  // 成功回调
  const onFinish = () => {};
  // 失败回调
  const onFinishFailed = () => {};

  interface Drone {
  	label: string;
  	value: string;
    disabled:boolean
  }

  const selectDroneData = ref<Drone[]>();


  interface selectData {
  	label: string;
  	value: string;
  	id?: string;
  }

  interface SelectCameraData {
  	[key: string]: selectData[];
  }

  const selectCameraData = ref<SelectCameraData>({});
		
watch(()=>createWaypointLineData,(data,oldData)=>{
	if(data){
		emit("updateStatus",data)
	}
},{
	deep:true
})


</script>

<style lang="scss" scoped >

  

  .m-8{
    margin-right: 8px
  }

  ::v-deep .ant-modal-close-x {
  	line-height: 38.67px !important;
  	width: 38.67px !important;
  	height: 38.67px !important;
  	color: #fff;
  }

  ::v-deep .ant-modal-content {
  	// color: hsla(0,0%,100%,.45);
  	background: #1c1c1c !important;
  	color: #fff !important;
  	// text-align: center !important;
  }
  ::v-deep .ant-modal-header {
  	background: #1c1c1c !important;
  	padding: 8px !important;
  	line-height: 24px !important;
  }
  ::v-deep .ant-modal-body {
  	padding: 32px 32px 0 32px !important;
  }

  ::v-deep .ant-modal-title {
  	background: #1c1c1c !important;
  	color: #fff !important;
  	text-align: center !important;
  }
  ::v-deep .ant-form-item {
  	margin-bottom: 16px;
  }
  ::v-deep .ant-form-item-label {
  	label {
  		color: #fff !important;
  		height: auto;
  	}
  }
  ::v-deep .ant-select-selector {
  	border-radius: 2px;
  	padding: 4px;
  	cursor: pointer;
  	color: #fff;
  	background-color: rgba(0, 0, 0, 0.5) !important;
  }
  
  .waypoint_drone_select_option{
    width:calc((100% - 16px )/3);
    background-color: #1c1c1c !important;
    font-size: 12px;
    height: 52px;
    box-shadow: none !important;
    :deep(.ant-radio-button-checked){
      background: rgba(45,140,240,.4);
      box-shadow: none !important;
      border: 1px solid #2d8cf0;
    }
  }

  .waypointType {
  	// width: 100px;
    width:calc((100% - 16px )/3);
  	height: 100px !important;
  	display: flex;
  	display: inline-flex;
  	flex-direction: column;
  	justify-content: center;
  	align-items: center;
  	background-color: #1c1c1c !important;
    box-shadow: none !important;
    margin-bottom: 8px;
    position: relative;
    // margin-right:8px;
    :deep(.ant-radio-button-checked){
      background: rgba(45,140,240,.4);
      box-shadow: none !important;
      border: 1px solid #2d8cf0;
    }
    .not-open-label{
      width: 72px;
      height: 26px;
      position: absolute;
      top: 0;
      right: 0;
      padding: 2px 8px;
      background: #5d5f61;
      color: hsla(0, 0%, 100%, .65);
      line-height: 26px;
    }
  }

 
  // .waypointType:hover{
  //   background: rgba(92, 173, 255, .4);
  //   border: 1px solid #5cadff;
  // }
  // .waypoint_name{
  //   .ant-select-selector{
  //     background: rgb(60, 60, 60) !important;
  //     color: rgb(255, 255, 255);
  //   }
  // }

  .waypointType_content {
  	display: flex;
  	height: 100%;
  	flex-direction: column;
  	justify-content: center;
  	align-items: center;
  	color: #fff !important;
   
   
  }
  .disabled{
    color: hsla(0, 0%, 100%, .25) !important;
    cursor: not-allowed;
    // border: none;
  }

  .flex_row_center {
  	display: flex;
  	flex-direction: row;
  	justify-content: center;
  	align-items: center;
  }

  .yuntai {
  	margin-bottom: 0px !important;
  	background-color: rgba(0, 0, 0, 0.5) !important;
  	// background: rgba(0,0,0,.5);
  	border-radius: 2px;
  	padding: 4px;
  	cursor: pointer;
  }

  .gimbal-0 {
  	right: 50%;
  	transform: translateX(50%);
  	bottom: 4px;
  }
  .component-payload-card {
  	position: absolute;
  	width: 150px;
  }
  .component-payload-card {
  	background: rgba(0, 0, 0, 0.5);
  	border: 1px solid hsla(0, 0%, 100%, 0.2);
  	border-radius: 2px;
  	padding: 4px;
  	cursor: pointer;
  }
  .component-payload-card .payload-card-title {
  	font-size: 12px;
  	line-height: 20px;
  	color: #fff;
  }
  .flex-container-center,
  .flex-container-column-center {
  	justify-content: center;
  }
  .flex-container-align-center,
  .flex-container-center,
  .flex-container-column-center {
  	align-items: center;
  }
  .flex-container,
  .flex-container-align-center,
  .flex-container-center,
  .flex-container-column-center,
  .flex-container-direction-column,
  .flex-container-justify-between {
  	display: flex;
  }

  .component-payload-card .payload-point {
  	display: block;
  	width: 6px;
  	height: 6px;
  	border-radius: 50%;
  	margin-right: 4px;
  }

  .component-payload-card .payload-card-add-text {
  	font-size: 12px;
  	line-height: 20px;
  	color: #fff;
  }

  .payload-card-overlay {
  	background: #000;
  	border-radius: 2px;
  	border: 1px solid hsla(0, 0%, 100%, 0.2);
  }
  .wayline-app-ant-dropdown {
  	box-sizing: border-box;
  	margin: 0;
  	padding: 0;
  	color: #595959;
  	font-size: 14px;
  	font-variant: tabular-nums;
  	line-height: 1.5715;
  	list-style: none;
  	font-feature-settings: normal;
  	position: absolute;
  	top: -9999px;
  	left: -9999px;
  	z-index: 1049;
  	display: block;
  }
  .payload-card-overlay .content-list {
  	color: #fff;
  	font-size: 14px;
  	line-height: 22px;
  }
  .payload-card-overlay .content-item.selected {
  	background: #232323;
  	color: #2b85e4;
  }
  .payload-card-overlay .content-item {
  	min-height: 32px;
  	padding: 5px 12px;
  	cursor: pointer;
  }
  .payload-card-overlay .content-item {
  	min-height: 32px;
  	padding: 5px 12px;
  	cursor: pointer;
  }

  .waypoint_drone_select {
  	::v-deep .ant-select-dropdown-menu-item:hover {
  		// background: linear-gradient(90deg, rgba(27, 139, 243, 0.3) -0.14%, rgba(27, 139, 243, 0) 99.86%) !important;

  		background: #3c3c3c !important;
  	}
  	::v-deep .ant-select-dropdown-menu-item-selected {
  		color: #fff !important;
  		// background: linear-gradient(90deg, #1B8BF3 -0.14%, rgba(27, 139, 243, 0) 99.86%) !important;

  		background: #3c3c3c !important;
  	}
  	::v-deep .ant-select-dropdown-menu-item-active {
  		// background: linear-gradient(90deg, #1B8BF3 -0.14%, rgba(27, 139, 243, 0) 99.86%) !important;

  		background: #3c3c3c !important;
  	}

  	::v-deep .ant-select-selector {
  		border: 1px solid transparent !important;
  		background: #3c3c3c !important;
  		color: #fff !important;
  	}
  	color: #fff !important;
  }

  // .waypoint_drone_select_op:hover{
  //   background: red !important;
  // }
  // .waypoint_drone_select_op

  // &:deep(.ant-modal-content){
  //   background-color:#1c1c1c !important;
  // }

  // &:deep(.ant-modal-header){
  //     background: #1c1c1c !important;
  // }

  // &:deep(.ant-modal-title){
  //     background: #1c1c1c !important;
  //     color: #fff !important;
  //     text-align: center !important;
  // }
</style>


<style>
  /* .waypoint_drone_select_op{
  background: #3c3c3c !important;
  }

  .waypoint_drone_select_op:hover{

  background-color: #3c3c3c !important;
  } */

  /* .waypoint_drone_select_option:hover{
  } */
</style>
/*
 * @Date: 2024-04-19 16:33:58
 * @LastEditors: jiangchao
 * @LastEditTime: 2024-04-23 20:04:36
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \redfly-web\src\api\service\fuya.ts
 */

import { getPictureCode,getAccountCaptcha, loginUser, userInfo } from "../fuya/user"


export const login = async () => {
  try {
    
    // 设置二维码
    await getPictureCode()
    // 获取验证信息
    let cap = await getAccountCaptcha()
    // 登录
    if(!cap){
      throw new Error("未获取验证信息")
    }
    let loginres = await loginUser({
      smsCode:cap.data
    })

    if(!loginres){
      throw new Error("登录失败")
    }
    localStorage.setItem('username', '18888888888');
    localStorage.setItem('token', loginres.data.token);
  } catch (error) {
  }
} 
export const userInfoData = async  () => {
  let user = await userInfo()
  console.info("user",user)
}
/*
 * @Date: 2023-08-30 11:40:26
 * @LastEditors: jiangchao
 * @LastEditTime: 2023-08-30 13:12:54
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \redfly-web\src\vue3CronCom.ts
 */
import type { App } from 'vue';

import Cron from '/@/components/vue3-cron/Cron.vue';

Cron.install = function(app: App) {
    app.component(Cron.name, Cron);
    return app;
};

export default Cron
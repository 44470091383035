export const CURRENT_CONFIG = {

  // license
  appId: '126079', // You need to go to the development website to apply.
  appKey: '8d44f9bb14cacb64b445c8a9dd3f643', // You need to go to the development website to apply.
  appLicense: 'vX017uRJnpAiICbZKI0etZCsgjx4Rd6zFsR2Iaui9qWws/6v7IDRNyWjk5gTQwh/Reo4E1A4cJdSKaeqqsUa+wGGcsuXgTne/3MTT5y3mCLdfvy9SqdOot2CSZyH/i4xpXkbb/8Dar+IYfYAEWP0kNjL9KjSolrZjH2tWJnLDLs=', // You need to go to the development website to apply.


  // // http
  // baseURL: 'https://jw.redfly.tech/vxapi/',
  // websocketURL: 'wss://js.redfly.tech/vxapi/api/v1/ws',

  //  baseURL: 'http://192.168.3.228:6791', // This url must end with "/". Example: 'http://192.168.1.1:6789/'


  // baseURL: 'http://192.168.8.104:6791/dji',
  baseURL: 'http://http://32.121.75.13:6791/dji',
  // baseURL: 'https://uav3.redfly.tech/dji',
  // baseURL: 'http://192.168.3.4:6791',
  // websocketURL: 'ws://121.43.121.94:6791/api/v1/ws',
  // websocketURL: 'ws://127.0.0.1:8080/dji/api/v1/ws',
  websocketURL: 'ws://32.121.75.13:6791/dji/api/v1/ws',

  // baseURL: 'http://192.168.3.145:6789/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  // websocketURL: 'ws://192.168.3.20:6791/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'rtmp://127.0.0.1/live/', // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.', // SIP服务器地址
  gbServerPort: 'Please enter the server port.', // SIP服务器端口
  gbServerId: 'Please enter the server id.', // 服务器编号
  gbAgentId: 'Please enter the agent id', // 设备编号（视频终端的设备国际编号，20位数字）
  gbPassword: 'Please enter the agent password', // 注册密码
  gbAgentPort: 'Please enter the local port.', // SIP本地端口
  gbAgentChannel: 'Please enter the channel.', // 本地通道（视频通道编号，20位数字，与设备编号保持一致）
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora 0c0e0966bb694f808284606e9fdb8f48
  // 0c0e0966bb694f808284606e9fdb8f48
  agoraAPPID: '0c0e0966bb694f808284606e9fdb8f48',
  agoraToken: '007eJxTYPjD6Jt2MoR50nKpo7bXJnl0XVdyv7qlWMqakb/HmONQvJYCg0GyQaqBpZlZUpKZpUmahYGFkYWJmYFZqmVaSpJFmolFy879yQ2BjAypb8qZGRkgEMRnZUjOyS9NYWAAAK5jHbk=',
  agoraChannel: 'cloud',

  // map
  // You can apply on the AMap website.
  amapKey: 'a1981bf07e75ee503532f5f828392be8',
  aMapSecurityConfig: '822b2fcc3fa56656c3f86b14be07362a',

}

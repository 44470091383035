/*
 * @Date: 2024-04-18 10:02:37
 * @LastEditors: jiangchao
 * @LastEditTime: 2024-04-25 19:10:28
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \redfly-web\src\api\fuya\wayline.ts
 */

import { login } from '../service/fuya'
import requiest from '/@/api/http/fuyaReq'

// 查询设备
export const selectDockInfo = async (): Promise<any> =>{
  if(!~location.host.indexOf('32.121.75')){
    return {}
  }
  try {
    let droneInfo = await requiest.post("/api/droneBox/view",{
      page: 1,
      rows: 9999
    })
    if(droneInfo.status == 409 || droneInfo.status == 401){
      await login()
  
      return await selectDockInfo()
    }
    return droneInfo.data
  } catch (error) {
    await login()
    return await selectDockInfo()
  }
}


export const getOrgTree = async (): Promise<any>=>{
  if(!~location.host.indexOf('32.121.75')){
    return {}
  }
  
  // let droneInfo = await requiest.post("/api/org/getOrgTree",{
  //   apiType: 10,
  //   keyWord:  ""
  // })

  // if(droneInfo.status == 409||droneInfo.status==401){
  //   await login()

  //   return await getOrgTree()
  // }
  // return droneInfo.data
  if(!localStorage.getItem("username") || !localStorage.getItem("token")){
    await login()
  }
  try {
    let droneInfo = await requiest.post("/api/org/getOrgTree",{
      apiType: 10,
      keyWord:  ""
    })
    console.info("droneInfo",droneInfo)
    debugger
    if(droneInfo.status == 409 || droneInfo.status == 401){
      await login()
      return await getOrgTree()
    }
    return droneInfo.data
  } catch (error) {
    await login()
    return await getOrgTree()
  }
}

export const getByDeviceNumber = async (deviceNumber:string): Promise<any> =>{
  if(!~location.host.indexOf('32.121.75')){
    return {}
  }
  try {
    let droneInfo = await requiest.post("/api/deviceUrl/getByDeviceNumber",{
      deviceNumber,
      deviceType:0
    })
    if(droneInfo.status == 409 || droneInfo.status == 401){
      await login()
  
      return await getByDeviceNumber(deviceNumber)
    }
    return droneInfo.data
  } catch (error) {
    await login()
    return await getByDeviceNumber(deviceNumber)
  }
}

// 更具机场id查询无人机信息

export const queryDroneInfo = async (deviceNumber:string): Promise<any> => {
  if(!~location.host.indexOf('32.121.75')){
    return {}
  }
  try {
    let droneInfo = await requiest.post("/api/droneBox/getDeviceMap",{
      deviceNumber
    })
    if(droneInfo.status == 409){
      await login()
  
      return await queryDroneInfo(deviceNumber)
    }
    return droneInfo.data
  } catch (error) {
    await login()
  
    return await queryDroneInfo(deviceNumber)
  }
}

export const getDroneInfo = async(deviceNumber:string): Promise<any> => {
  if(!~location.host.indexOf('32.121.75')){
    return {}
  }
  try {
    let droneInfo = await requiest.post("/api/drone/queryDroneInfo",{
      deviceNumber
    })
    if(droneInfo.status == 409){
      await login()
  
      return await getDroneInfo(deviceNumber)
    }
    return droneInfo.data
  } catch (error) {
    await login()
  
    return await getDroneInfo(deviceNumber)
  }
}

// Upload Wayline file
export const importKmzTongBuFile = async function (file: {}): Promise<any> {
  if(!~location.host.indexOf('32.121.75')){
    return {}
  }
	try {
    // const url = `/api/route/importRouteInfo`;
  const url = '/api/route/batchImportRoute'
    const result = await requiest.post(url, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if(result.status == 409){
      await login()

      return await importKmzTongBuFile(file)
    }
    return result;
  } catch (error) {
    await login()

    return await importKmzTongBuFile(file)
  }
};

// 查询 航迹文件
export const getWaylineData = async (): Promise<any>=>{
  if(!~location.host.indexOf('32.121.75')){
    return {}
  }
  try {
    const url = `/api/route/view`;

    const result = await requiest.post(url, {
      deviceName: "",
      page:1,
      routeName: "",
      rows: 9999
    });
    if(result.status == 409){
      await login()

      return await getWaylineData()
    }
    return result.data;
  } catch (error) {
    await login()
    return await getWaylineData()
  }
}

// 开始任务 | 执行航线
export const startTask = async (taskId:number): Promise<any>=>{
  try {
    if(!~location.host.indexOf('32.121.75')){
      return {}
    }
    const url = `/api/flight/startTask`;
  
    const result = await requiest.post(url, {
      queryType: "20",
      taskId
    });
    if(result.status == 409){
      await login()
  
      return await startTask(taskId)
    }
    return result.data;
  } catch (error) {
    await login()
  
    return await startTask(taskId)
  }
}


// 预约航线加入列表
export const addTask = async (bodyData:{
  taskName:string
  routeList:{id:number,routeVersion:number}[]    //航线列表
  taskType?:string
  takeoffTime?:number                              // 起飞时间，unix时间戳
  intervalHour?:number
  description?:string
  uploaded?:boolean
  taskPictureUrl?:string | null
  taskExecuteType?:string
  partIds?:number[]
  algorithmSwitch?:boolean
  securityPolitySwitch?:boolean
}): Promise<any> => {
  try {
    if(!~location.host.indexOf('32.121.75')){
      return {}
    }
    const url = `/api/task/add`;
    bodyData.algorithmSwitch = false
    bodyData.taskExecuteType = '10'
    bodyData.taskType = "10"
    bodyData.taskPictureUrl = null
    bodyData.securityPolitySwitch = false
    const result = await requiest.post(url, bodyData);
    if(result.status == 409){
      await login()
      return await addTask(bodyData)
    }

    return result.data;

  } catch (error) {
    await login()
    return await addTask(bodyData)
  }
}

interface AddReserveTask {
  config:{}
  cyclic: boolean
  reserveTime:number
  taskId:number
}

export const addReserveTask = async (bodyData:AddReserveTask): Promise<any>=> {
  const url = `/api/task/addReserveTask`;
  try {
      const result = await requiest.post(url, bodyData);
      return result.data;
  } catch (error) {
      await login()
      return await addReserveTask(bodyData)
  }


}

// 根据机场查航线信息
export const getRoutesByDroneBox = async (dockId:number): Promise<any>=>{
  try {
    if(!~location.host.indexOf('32.121.75')){
      return {}
    }
    const url = `/api/flight/getRoutesByDroneBox`;
  
    const result = await requiest.post(url, {
      droneBoxId:dockId
    });
    if(result.status == 409){
      await login()
  
      return await getRoutesByDroneBox(dockId)
    }
    return result.data;
  } catch (error) {
    await login()
  
    return await getRoutesByDroneBox(dockId)
  }
}


// 查询航线结束信息 /api/flight/queryTaskEndInfo
export const queryTaskEndInfo = async (body:any): Promise<any> => {
  if(!~location.host.indexOf('32.121.75')){
    return {}
  }
  try {
    const url = `/api/flight/queryTaskEndInfo`;
    const result = await requiest.post(url, {
      queryType:"20",
      taskId:body.taskId,
      jobId:body.jobId
    });
    if(result.status == 409){
      await login()

      return await queryTaskEndInfo(body)
    }
    return result.data;
  } catch (error) {
    await login()

    return await queryTaskEndInfo(body)
  }
}






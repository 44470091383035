/*
 * @Date: 2023-02-01 14:22:55
 * @LastEditors: jiangchao
 * @LastEditTime: 2024-04-17 14:39:39
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \redfly-web\src\main.ts
 */
import App from './App.vue';

import router from './router';
import { antComponents } from './antd';
import { CommonComponents } from './use-common-components';
import 'virtual:svg-icons-register';
// import AntDesignVue from 'ant-design-vue';
import store, { storeKey } from './store';
import { createInstance } from '/@/root';
import '/@/styles/index.scss';
import '/@/assets/iconfont/iconfont.css';
import '/@/assets/iconfont_createWayline/iconfont.css';
// import 'amfe-flexible';
import resizable from './directives/resize';
import { useDirectives } from './directives';
import VueViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

import Vue3Cron from 'vue3-cron-antd';

import './vue3CronCom';

const app = createInstance(App);

app.use(VueViewer, {
	defaultOptions: {
		// zIndex: 3000,
		inline: true, // Default: false. Enable inline mode.
		button: true, // Show the button on the top-right of the viewer.
		// navbar: true, // Specify the visibility of the navbar.
		title: true, // Specify the visibility and the content of the title.
		// toolbar: true, // Specify the visibility and layout of the toolbar its buttons.
		tooltip: true, // Show the tooltip with image ratio (percentage) when zooming in or zooming out.
		// movable: true, // Enable to move the image.
		// zoomable: true, // Enable to zoom the image.
		// rotatable: false, // Enable to rotate the image.
		scalable: true, // Enable to scale the image.
		// transition: true, // Enable CSS3 Transition for some special elements.
		fullscreen: true, // Enable to request full screen when play.
		// keyboard: true, // Enable keyboard support.
		// // url: "src", // De
	},
});
const tenantData = JSON.parse(localStorage.getItem('tenantData') || '{}');
document.title = tenantData.name || '江苏省飞睿得科技有限公司';
app.use(store, storeKey);
app.use(resizable);
app.use(router);
app.use(CommonComponents);
// app.use(AntDesignVue);
app.use(antComponents);
app.use(useDirectives);
app.use(Vue3Cron);
app.mount('#demo-app');

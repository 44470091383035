
import requiest from '/@/api/http/fuyaReq'

/**
 * 获取验证码
 * api/user/getPictureCode
 */
export const getPictureCode = async (account:string = '18888888888') => {
  let pictureCode = await requiest.get("/api/user/getPictureCode",{
    params: {
      account,
    },
  })
  return pictureCode
} 


/**
 * 获取用户验证信息
 */
export const getAccountCaptcha = async (account:string = '18888888888') => {
  let captcha = await requiest.get("/api/user/getAccountCaptcha",{
    params: {
      account,
    },
  })
  return captcha.data
}

interface bodyData{
	account?: string,
  pwd?: string,
  smsCode: string,
}

/**
 * 登录
 */
export const loginUser = async(bodyData:bodyData)=>{
  let defaultBody = {
    account: "18888888888",
    pwd: "Foia2021!",
  }
  let body = {...defaultBody,...bodyData}
  let login = await requiest.post("/api/user/login",body)
  return login.data
}

/**
 * 获取用户信息
 */
export const userInfo = async()=>{
  let userInfo = await requiest.post("/api/user/info")
  return userInfo.data
}








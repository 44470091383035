/*
 * @Date: 2024-04-18 13:07:33
 * @LastEditors: jiangchao
 * @LastEditTime: 2024-04-23 09:59:38
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \redfly-web\src\api\http\fuyaReq.ts
 */
import axios from 'axios';
import { FuYaLocalstorageKey } from '/@/types/enums';

function getAuthToken() {
	return localStorage.getItem(FuYaLocalstorageKey.Token);
}


const instance = axios.create({
	baseURL:"/fuya"
});

// Authorization 白名单 
let checkAuthorizationWhiteList = () => {
	return ["/getPictureCode","/getAccountCaptcha","/login"]
}

instance.interceptors.request.use(
	(config) => {
		config.maxContentLength = 1024 * 1024 * 1024 * 1024;
		let isCheck = true
		for(let ckeckAuthoriza of checkAuthorizationWhiteList()){
			if(config && config.url && ~config.url?.indexOf(ckeckAuthoriza)){
				isCheck = false
			}
		}
		if(isCheck){
			config.headers["Authorization"] = getAuthToken()
		}
		// config.headers["Authorization"] = getAuthToken()
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		console.info("response",response)
		return response;
	},
	(err) => {
		console.info("err11111111111111111111",err)
		return Promise.reject(err);
	}
);

export default instance;

<!--
 * @Date: 2023-08-30 11:56:23
 * @LastEditors: jiangchao
 * @LastEditTime: 2023-08-30 13:17:35
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \redfly-web\src\components\vue3-cron\_Internal\InputNumber.vue
-->
<template>
    <a-input-number
        v-model:value="value"
        :min="range[0]"
        :max="range[1]"
        :formatter="formatter"
        :parser="parser"
        size="small"
        @change="onChange"
    />
</template>

<script lang="ts">
import { WEEK } from '/@/shared/constants';
import { formatterWeek, parserWeek } from '/@/shared/utils.ts';

export default {
    props: {
        modelValue: [Number, String],
        range: {
            required: true,
            type: Array,
        },
        fieldValue: String,
        locale: {
            type: String,
        },
    },
    emits: ['update:modelValue', 'change'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        formatter() {
            return (this.fieldValue === WEEK) ? ($event) => formatterWeek($event, this.locale) : null;
        },
        parser() {
            return (this.fieldValue === WEEK) ? ($event) => parserWeek($event, this.locale) : null;
        },
    },
    methods: {
        onChange(value) {
            this.$emit('change', value);
        },
    },
}
</script>

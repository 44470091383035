/*
 * @Author: gejia 905034981@qq.com
 * @Date: 2024-01-13 15:20:01
 * @LastEditors: gejia 905034981@qq.com
 * @LastEditTime: 2024-01-13 16:28:25
 * @FilePath: \redfly-web\src\directives\resize.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
	install: (app) => {
		const sideArr = ['right', 'left', 'top', 'bottom'];
		const errmsg = 'resizable needs string value of: ' + sideArr;
		const minSize = 40;
		const dragSize = 5;

		app.directive('resizable', {
			mounted(el, binding, vnode, oldVnode) {
				const dragable = {};
				const oriCur = el.style.cursor;
				const sides = binding.value.replace(' ', '').split(',');
				let dragSide = '';
				let dragging = false;

				if (sides.length === 0) {
					throw errmsg;
				}
				for (let i = 0; i < sides.length; i++) {
					if (sideArr.indexOf(sides[i]) < 0) {
						throw errmsg;
					}
					dragable[sides[i]] = true;
				}

				el.addEventListener('mousemove', (e) => {
					if (dragging) return;

					if (dragable['right'] && el.offsetWidth - e.offsetX < dragSize) {
						el.style.cursor = 'ew-resize';
						dragSide = 'right';
					} else if (dragable['left'] && e.offsetX < dragSize) {
						el.style.cursor = 'ew-resize';
						dragSide = 'left';
					} else if (dragable['top'] && e.offsetY < dragSize) {
						el.style.cursor = 'ns-resize';
						dragSide = 'top';
					} else if (dragable['bottom'] && el.offsetHeight - e.offsetY < dragSize) {
						el.style.cursor = 'ns-resize';
						dragSide = 'bottom';
					} else {
						el.style.cursor = oriCur;
						dragSide = '';
					}
				});

				el.addEventListener('mousedown', (e) => {
					if (!dragSide) return;

					dragging = true;
					const cstyle = window.getComputedStyle(el);
					const width = Number.parseInt(cstyle.width);
					const height = Number.parseInt(cstyle.height);
					const left = Number.parseFloat(cstyle.left);
					const elW = width > 0 ? width : el.offsetWidth;
					const elH = height > 0 ? height : el.offsetHeight;
					const clientX = e.clientX;
					const clientY = e.clientY;

					const movefun = (e) => {
						e.preventDefault();
						const containerRect = el.parentNode.getBoundingClientRect();
						el.style.maxWidth = containerRect.width - 20 + 'px';
						el.style.maxHeight = containerRect.height - 10 + 'px';
						if (e.clientX - 10 < containerRect.left || e.clientY - 10 < containerRect.top) return

						if (dragSide === 'right' && (e.clientX > clientX || el.offsetWidth >= minSize)) {
							el.style.width = elW + (e.clientX - clientX) + 'px';
						} else if (dragSide === 'left' && (e.clientX < clientX || el.offsetWidth >= minSize)) {
							el.style.width = elW + (clientX - e.clientX) + 'px';
							el.style.left = left - (clientX - e.clientX) + 'px';

							// el.style.transform = `translate(${clientX - e.clientX > 0 ? -(clientX - e.clientX) : -(clientX - e.clientX)}px, 0)`;
						} else if (dragSide === 'top' && (e.clientY < clientY || el.offsetHeight >= minSize)) {
							el.style.height = elH + (clientY - e.clientY) + 'px';
						} else if (dragSide === 'bottom' && (e.clientY > clientY || el.offsetHeight >= minSize)) {
							el.style.height = elH + (e.clientY - clientY) + 'px';
						}
					};
					const removefun = () => {
						dragging = false;
						document.removeEventListener('mousemove', movefun);
						document.removeEventListener('mouseup', removefun);
					};

					document.addEventListener('mousemove', movefun);
					document.addEventListener('mouseup', removefun);
				});
			},
		});
	},
};

import axios from 'axios';
import { uuidv4 } from '/@/utils/uuid';
import { CURRENT_CONFIG } from './config';
import { message } from 'ant-design-vue';
import router from '/@/router';
import { ELocalStorageKey, ERouterName, EUserType } from '/@/types/enums';
export * from './type';

const REQUEST_ID = 'X-Request-Id';
function getAuthToken() {
	return localStorage.getItem(ELocalStorageKey.Token);
}
function getAuthTokenNew() {
	return localStorage.getItem(ELocalStorageKey.TokenNew);
}
function getTenantId() {
	return localStorage.getItem('tenantId');
}

const instance = axios.create({
	// withCredentials: true,
	// headers: {
	//   'Content-Type': 'application/json',
	// },
	// timeout: 12000,
});

const loginData: any = {
	'user/setNotify': {
		value: 'saas',
		isShowToken: true,
	},
	'get-id-by-name': {
		value: 'saas',
		isShowToken: false,
	},
	'tenant/get?': {
		value: 'saas',
		isShowToken: true,
	},
	'mqtt/login': {
		value: 'saas',
		isShowToken: false,
	},
	'auth/login': {
		value: 'saas',
		isShowtenantId: true,
		isShowToken: false,
	},
	'dept/list-all-simple': {
		value: 'saas',
		isShowToken: true,
	},
};

instance.interceptors.request.use(
	(config) => {
		config.maxContentLength = 1024 * 1024 * 1024 * 1024;
		if (!config.headers['Content-Type']) {
			config.headers['Content-Type'] = 'application/json';
			// config.headers["Access-Control-Allow-Origin"]= "*"
		}

		config.headers['Authorization'] = 'Bearer ' + getAuthTokenNew();

		let baseUrl = '/dji';

		let isShowSass = false;
		for (let l in loginData) {
			let ele = loginData[l];
			if (config.url && config.url.includes(`/${l}`)) {
				isShowSass = true;
				if (ele.isShowToken) {
					config.headers[ELocalStorageKey.Token] = getAuthToken();
					config.headers['tenant-id'] = getTenantId();
					config.headers['Authorization'] = 'Bearer ' + getAuthTokenNew();
				} else {
					if (!ele.isShowtenantId) {
						delete config.headers['tenant-id'];
					}
					delete config.headers[ELocalStorageKey.Token];
					delete config.headers['Authorization'];
				}
				// baseUrl = `${CURRENT_CONFIG.baseURL}${ele.value}`
				if (!CURRENT_CONFIG.baseURL.includes('dji')) {
					baseUrl = CURRENT_CONFIG.baseURL + '/dji';
				}
				baseUrl = baseUrl.replace('dji', `${ele.value}`);
				if (baseUrl.includes('192.168')) {
					baseUrl = `https://lssl.web.redfly.tech/saas`;
				}
			}
		}
		if (!isShowSass) {
			if (baseUrl == CURRENT_CONFIG.baseURL) {
				config.headers['tenant-id'] = getTenantId();
				config.headers['Authorization'] = 'Bearer ' + getAuthTokenNew();
				config.headers[ELocalStorageKey.Token] = getAuthToken();
				baseUrl = CURRENT_CONFIG.baseURL;
			} else {
				config.headers['tenant-id'] = getTenantId();
				config.headers['Authorization'] = 'Bearer ' + getAuthTokenNew();
				config.headers[ELocalStorageKey.Token] = getAuthToken();
			}
		}

		// /weathernew

		if (config.url.includes('/system/tenant/get-id-by-name')) {
			delete config.headers['tenant-id'];
		}
		// if (config.url.includes('/weathernew')) {
		// 	baseUrl = 'http://weathernew.pae.baidu.com';
		// }

		config.baseURL = baseUrl;

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		if (response.data.code == 401 || response.data.code == 501) {
			const flag: number = Number(localStorage.getItem(ELocalStorageKey.Flag));
			console.log('requestflag', flag);
			switch (flag) {
				case EUserType.Web:
					router.push(ERouterName.PROJECT);
					break;
				case EUserType.Pilot:
					router.push(ERouterName.PILOT);
					break;
				default:
					router.push(ERouterName.PROJECT);
					break;
			}
		}

		if (response.data.code && response.data.code !== 0) {
			if (response.data && response.data.message) {
			}
		}

		return response;
	},
	(err) => {
		const requestId = err?.config?.headers && err?.config?.headers[REQUEST_ID];
		if (requestId) {
			console.info(REQUEST_ID, '：', requestId);
		}
		console.info('url: ', err?.config?.url, `【${err?.config?.method}】 \n>>>> err: `, err);

		let description = '-';
		if (err.response?.data && err.response.data.message) {
			description = err.response.data.message;
		}
		if (err.response?.data && err.response.data.result) {
			description = err.response.data.result.message;
		}
		// @See: https://github.com/axios/axios/issues/383
		if (!err.response || !err.response.status) {
			// message.error('网络异常，请检查后端服务，然后重试')
			return;
		}
		if (err.response?.status !== 200) {
			// message.error(`ERROR_CODE: ${err.response?.status}`)
		}
		// if (err.response?.status === 403) {
		//   // window.location.href = '/'
		// }
		if (err.response?.status === 401) {
			console.info('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', err);
			// console.error(err.response?.data.message)
			if (err.config.url.includes('/token/refresh')) {
				router.push(ERouterName.PILOT);
			} else {
				const flag: number = Number(localStorage.getItem(ELocalStorageKey.Flag));

				console.info('flag', flag);

				switch (flag) {
					case EUserType.Web:
						router.push(ERouterName.PROJECT);
						break;
					case EUserType.Pilot:
						router.push(ERouterName.PILOT);
						break;
					default:
						router.push(ERouterName.PROJECT);
						break;
				}
			}
		}

		return Promise.reject(err);
	}
);

export default instance;

/*
 * @Author: gejia 905034981@qq.com
 * @Date: 2024-01-19 11:51:56
 * @LastEditors: gejia 905034981@qq.com
 * @LastEditTime: 2024-01-19 11:57:02
 * @FilePath: \redfly-web\src\hooks\use-g-author.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export function useUserAuthor() {
	const getAiAuthor = () => {
		let tenantData = localStorage.getItem('tenantData');
		if (tenantData) {
			let data = JSON.parse(tenantData);
			let hasAi = data.ai === 0 ? true : false;
			return hasAi;
		}
	};
	return {
		getAiAuthor,
	};
}

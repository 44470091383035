<!--
 * @Date: 2024-11-14 17:45:47
 * @LastEditors: jiangchao
 * @LastEditTime: 2024-11-26 14:19:31
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \redfly-web\src\pages\ai\component\editor.vue
-->
<template>
  
   <div ref="divRef" class="aiedit" :class="!isHeight?'':'heg'">
    <!-- <div class="aie-container-footer"></div> -->

   </div>
</template>

<script setup lang="ts">
import {AiEditor} from "aieditor";
import "aieditor/dist/style.css"
import { getAiTypeByWare } from '/@/api/ai';
import {onMounted, onUnmounted, ref,defineProps} from "vue";

const divRef = ref();
let aiEditor: AiEditor | null = null;

interface Props {
  aiType:string
}

const isHeight = ref(false)

const props = defineProps<Props>()
watch(()=>props.aiType,async (item)=>{
  console.info("111111111111111111111111")
  let datas = await getAiTypeByWare(item)
  console.info("datas",datas)
})

const updateHeightData = () => {
  if(aiEditor){
    aiEditor.setEditable(false)
  }
  isHeight.value = true
}

onMounted(() => {
  // createDatas()
})

const createDatas = async (item?:any,editable=true) => {
  debugger
  if(aiEditor){
    aiEditor.destroy();
  }
  let content = ''
  if(item.value){
    let datas = await getAiTypeByWare(item.value)
    console.info("datas",datas)
    let res = datas.data[0]
    if(res && res.description){
      content = res.description
    }
  }
  aiEditor = new AiEditor({
    element: divRef.value as Element,
    placeholder: "点击输入内容...",
    content: content,
    editable,
    contentRetention:false,
    toolbarKeys: ["undo", "redo", "brush", "eraser", 
        "|", "heading", "font-family", "font-size", 
        "|", "bold", "italic", "underline", "strike", "link", "code", "subscript", "superscript", "hr", "todo", "emoji", 
        "|", "highlight", "font-color",
        "|", "align", "line-height", 
        "|", "bullet-list", "ordered-list", "indent-decrease", "indent-increase", "break", 
        "|", "image", "video", "attachment", "quote", "code-block", "table", 
        "|", "source-code", "printer", "fullscreen"
    ],
    textSelectionBubbleMenu:{
      enable: true,
      items: [ "Bold", "Italic", "Underline", "Strike", "code", "comment"],
    }
  })
}


const initEditor = () => {
  aiEditor?.clear()
}

const getAiEditor = () => {
  console.info("aiEditor",aiEditor)
  return aiEditor;
}

const getAiGetHtml = () => {
  return aiEditor?.getHtml()
}

const destroy = () => {
  console.info("destroy")
  aiEditor && aiEditor.destroy();
  aiEditor = null;
}


defineExpose({
  getAiGetHtml,
  createDatas,
  initEditor,
  updateHeightData,
  getAiEditor
})

onUnmounted(() => {
  aiEditor && aiEditor.destroy();
})
</script>
<style lang="scss" scoped>
.aiedit{

  max-width: 480px;
  height: 600px;
}

.heg{
  height: 100% !important;
  ::v-deep aie-header{
    display: none !important;
  }

}


</style>

<style>
aie-footer>div{
  display: none !important;
}
</style>
import axios from 'axios';
import { uuidv4 } from '/@/utils/uuid';
import { CURRENT_CONFIG } from './config';
import { message } from 'ant-design-vue';
import router from '/@/router';
import { ELocalStorageKey, ERouterName, EUserType } from '/@/types/enums';
export * from './type'

const REQUEST_ID = 'X-Request-Id';

const instance = axios.create({
	
});


instance.interceptors.request.use(
	(config) => {
		config.maxContentLength = 1024 * 1024 * 1024 * 1024;
		if (!config.headers['Content-Type']) {
			config.headers['Content-Type'] = 'application/json';
			// config.headers["Access-Control-Allow-Origin"]= "*"
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		if (response.data.code == 401 || response.data.code == 501) {
			const flag: number = Number(localStorage.getItem(ELocalStorageKey.Flag));
			console.log('requestflag', flag);
			switch (flag) {
				case EUserType.Web:
					router.push(ERouterName.PROJECT);
					break;
				case EUserType.Pilot:
					router.push(ERouterName.PILOT);
					break;
				default:
					router.push(ERouterName.PROJECT);
					break;
			}
		}

		if (response.data.code && response.data.code !== 0) {
			if (response.data && response.data.message) {
			}
		}

		return response;
	},
	(err) => {
		const requestId = err?.config?.headers && err?.config?.headers[REQUEST_ID];
		if (requestId) {
			console.info(REQUEST_ID, '：', requestId);
		}
		console.info('url: ', err?.config?.url, `【${err?.config?.method}】 \n>>>> err: `, err);

		let description = '-';
		if (err.response?.data && err.response.data.message) {
			description = err.response.data.message;
		}
		if (err.response?.data && err.response.data.result) {
			description = err.response.data.result.message;
		}
		// @See: https://github.com/axios/axios/issues/383
		if (!err.response || !err.response.status) {
			// message.error('网络异常，请检查后端服务，然后重试')
			return;
		}
		if (err.response?.status !== 200) {
			// message.error(`ERROR_CODE: ${err.response?.status}`)
		}
		// if (err.response?.status === 403) {
		//   // window.location.href = '/'
		// }
		if (err.response?.status === 401) {
			// console.error(err.response?.data.message)
			if (err.config.url.includes('/token/refresh')) {
				router.push(ERouterName.PILOT);
			} else {
				const flag: number = Number(localStorage.getItem(ELocalStorageKey.Flag));

				console.info('flag', flag);

				switch (flag) {
					case EUserType.Web:
						router.push(ERouterName.PROJECT);
						break;
					case EUserType.Pilot:
						router.push(ERouterName.PILOT);
						break;
					default:
						router.push(ERouterName.PROJECT);
						break;
				}
			}
		}

		return Promise.reject(err);
	}
);

export default instance;

<template>
  <div class="fuya">
    <a-form ref="valueRef" layout="horizontal" :hideRequiredMark="true" :rules="rules" :model="planBody">
      <a-form-item label="计划名称1" name="name" :labelCol="{ span: 24 }">
        <a-input style="background: black" placeholder="请输入计划名称" v-model:value="planBody.name" />
      </a-form-item>
       <!-- 设备 -->
       <a-form-item label="机场设备" :wrapperCol="{ offset: 7 }" v-model:value="planBody.dock_sn" name="dock_sn">
        <router-link :to="{ name: 'select-plan' }" @click="selectDevice">请选择设备</router-link>
      </a-form-item>
      <a-form-item v-if="planBody.dock_sn" style="margin-top: -15px">
        <div class="panel" style="padding-top: 5px">
          <div class="title">
            <a-tooltip :title="dock.nickname">
              <div class="pr10" style="width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
                {{ dock.nickname }}
              </div>
            </a-tooltip>
          </div>
          <div class="ml10 mt5" style="color: hsla(0, 0%, 100%, 0.65)">
            <span><RocketOutlined /></span>
            <span class="ml5">{{ dock.children?.nickname }}</span>
          </div>
        </div>
      </a-form-item>
      <!-- 航线 -->
      <a-form-item label="选择航线" :wrapperCol="{ offset: 7 }" name="file_id">
        <router-link :to="{ name: 'select-plan' }" @click="selectRoute"> 请选择航线 </router-link>
      </a-form-item>
      <a-form-item v-if="planBody.file_id" style="margin-top: -15px">
        <div class="wayline-panel" style="padding-top: 5px">
          <div class="title">
            <a-tooltip :title="wayline.name">
              <div class="pr10" style="width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
                {{ wayline.name }}
              </div>
            </a-tooltip>
            <div class="ml10"><UserOutlined /></div>
            <a-tooltip :title="wayline.user_name">
              <div class="ml5 pr10" style="width: 80px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
                {{ wayline.user_name }}
              </div>
            </a-tooltip>
          </div>
          <div class="ml10 mt5" style="color: hsla(0, 0%, 100%, 0.65)">
            <span><RocketOutlined /></span>
            <span class="ml5">经纬 M300</span>
            <span class="ml10"><CameraFilled style="border-top: 1px solid; padding-top: -3px" /></span>
            <span class="ml5" >
              M300
            </span>
          </div>
          <div class="mt5 ml10" style="color: hsla(0, 0%, 100%, 0.35)">
            <span class="mr10">Update at {{ new Date(wayline.update_time).toLocaleString() }}</span>
          </div>
        </div>
      </a-form-item>
     
     
      <!-- 任务类型 -->
      <a-form-item label="计划时间类型" :labelCol="{ span: 24 }" class="plan-timer-form-item">
        <div style="white-space: nowrap">
          <a-radio-group v-model:value="planBody.task_type" button-style="solid">
            <a-radio-button :value="TaskType.Immediate">立即</a-radio-button>
             <a-radio-button :value="TaskType.Single">预约</a-radio-button>
            <!--<a-radio-button :value="TaskType.RepeatTimed">重复定时</a-radio-button> -->
          </a-radio-group>
        </div>
      </a-form-item>
      <!-- 执行时间 -->
       <a-form-item label="执行时间" v-if="planBody.task_type === TaskType.Single" name="select_execute_time">
        <a-date-picker
          v-model:value="planBody.select_execute_time"
          @change="onChange"
          @ok="onOk"
		  format="YYYY-MM-DD HH:mm:ss"
          show-time
          placeholder="选择时间"
          style="width: 200px; background-color: #232323"
        />
      </a-form-item>
      <!--<a-form-item label="定时规则" :wrapperCol="{ offset: 7 }" v-if="planBody.task_type === TaskType.RepeatTimed">
        <div class="select_guize" @click="iconButton">{{ iconValue === '* * * * * ?' ? '请选择定时规则' : '已选择定时规则' }}</div>
      </a-form-item> -->
      <!-- <a-form-item
        class="chongfugongshi"
        label="请选择重复的公式："
        v-if="planBody.task_type === TaskType.RepeatTimed"
        :labelCol="{ span: 24 }"
      >
        <a-input-group compact>
          <a-input v-model:value="iconValue" />
          <a-tooltip title="请输入icon表达式">
            <a-button @click="iconButton">
              <template #icon><CopyOutlined /></template>
            </a-button>
          </a-tooltip>
        </a-input-group>
      </a-form-item> -->
      <a-form-item label="相对机场返航高度(m)" :labelCol="{ span: 24 }" name="rth_altitude">
        <a-input v-model:value="planBody.rth_altitude" style="background: black !important"> </a-input>
      </a-form-item>
      <a-form-item label="航线飞行中失联" :labelCol="{ span: 24 }" name="out_of_control_action" class="plan-timer-form-item">
        <div style="white-space: nowrap">
          <a-radio-group v-model:value="planBody.out_of_control_action" button-style="solid">
            <a-radio-button v-for="action in OutOfControlActionOptions" :value="action.value" :key="action.value">
              {{ action.label }}
            </a-radio-button>
          </a-radio-group>
        </div>
      </a-form-item>

      <a-form-item>
        <div class="footer">
          <a-button class="mr10" style="background: #3c3c3c" @click="closePlan">取消 </a-button>
          <a-button type="primary" @click="onSubmit" :disabled="disabled">确定 </a-button>
        </div>
      </a-form-item>
    </a-form>
    <div
      v-if="drawerVisible"
      style="position: absolute; left: 356px; width: 280px; height: 100vh; float: right; top: 0; z-index: 1000; color: white; background: #282828"
    >
      <div style="height: 100%">
        <router-view :name="routeName" />
      </div>
      <div style="position: absolute; top: 15px; right: 10px">
        <a style="color: white" @click="closePanel"><CloseOutlined /></a>
      </div>
    </div>
  </div>
</template>
<script setup lang='ts'>
import { inject,nextTick,defineProps, onMounted, onBeforeUnmount, onUnmounted, reactive, defineEmits, watch, ref, computed } from 'vue';
import { TaskType, OutOfControlActionOptions, OutOfControlAction } from '/@/types/task';
import { useMyStore } from '/@/store';
import { CloseOutlined, RocketOutlined, CameraFilled, UserOutlined, CopyOutlined } from '@ant-design/icons-vue';
import { getRoot } from '/@/root';
import moment, { Moment } from 'moment';
import { RuleObject } from 'ant-design-vue/es/form/interface';
import { message } from 'ant-design-vue';
import { ERouterName } from '/@/types/enums';
import { Device, EDeviceType } from '/@/types/device';
import { WaylineFile } from '/@/types/wayline';
import { startTask,addTask, addReserveTask } from '/@/api/fuya/wayline';

const root = getRoot();
const store = useMyStore();
const planBody = reactive({
	name: '',
	file_id: computed(() => store.state.waylineInfo.id),
	dock_sn: computed(() => store.state.dockInfo.device_sn),
	task_type: TaskType.Immediate,
	taskDays: JSON.stringify([moment().valueOf() + '', moment().valueOf() + '']),
	taskPeriods: [[moment().add(1, 'day').valueOf()], [moment().add(2, 'day').valueOf()]],
	select_execute_time: undefined as Moment | undefined,
	rth_altitude: '',
	out_of_control_action: OutOfControlAction.ReturnToHome,
});
const rules = {
	name: [
		{ required: true, message: '请输入计划名称。' },
		{ max: 20, message: '长度为1 ~ 20', trigger: 'blur' },
	],
	file_id: [{ required: true, message: '选择路线' }],
	dock_sn: [{ required: true, message: '选择设备' }],
	select_execute_time: [{ required: true, message: '选择开始时间' }],
	rth_altitude: [
		{
			validator: async (rule: RuleObject, value: string) => {
				if (!/^[0-9]{1,}$/.test(value)) {
					throw new Error('RTH高度相对需要数');
				}
			},
		},
	],
	out_of_control_action: [{ required: true, message: '选择丢失动作' }],
};

const yuyueshijian = ref("")

const onChange = (value: any, dateString: string) => {
	console.info('onChange====>', value, dateString);
}

const onOk = (value: any) => {
	console.info('onOk====>', value);

}

const iconValue = ref('* * * * * ?');
const disabled = ref(false);

const cornvisible = ref(false);

const iconButton = () => {
	cornvisible.value = true;
};
const routeName = ref('');
const drawerVisible = ref(false);


let currDockMode:any = inject("currDockMode")

const dock = computed<Device>(() => {
	return store.state.dockInfo;
});

const wayline = computed<WaylineFile>(() => {
	return store.state.waylineInfo;
});

const reserveTask = async () => {
	console.info("planBody.select_execute_time",planBody.select_execute_time)
	let taskBody = moment(planBody.select_execute_time?.$d).valueOf()
	let reserveTime = moment(planBody.select_execute_time?.$d).valueOf()
	
	console.info("wayline",wayline)
	console.info("taskBody",taskBody)
	console.info("reserveTime",reserveTime)
	let routeList = [{
		id:wayline.value.id,
		routeVersion:wayline.value.routeVersion
	}]

	let body = {
		taskName:planBody.name,
		routeList,
		taskType:"10",
		takeoffTime:taskBody,
		uploaded:true,
		taskExecuteType:"10"
	}
	let task = await addTask(body)
	if(task.resultCode == 200){
		message.success("加入任务列表成功")
		let d = task.data
		let taskId = d.id
		let body = {
			config:{},
			cyclic:false,
			reserveTime,
			taskId
		}
		let res = await addReserveTask(body)
		if(res.resultCode == 200){
			message.success("预约成功")
		}else{
			message.error(`预约失败，请检查预约时间是否和当前时间相差15分钟`)
		}
	}else{
		message.error("加入任务失败")
	}
	closePlan()



} 

// function closePlan() {
// 	root.$router.push('/' + ERouterName.TASK);
// }

const onSubmit = async () => {
  disabled.value = true;
  // store.dispatch('addPlan', planBody);
  console.info("wayLine",wayline)
  console.info("dock",dock)

	switch (planBody.task_type) {
		case TaskType.Immediate:
			let task =  await startTask(wayline.value.id)  
			if(task.resultCode != '200' && task.resultMessage){
				message.error(task.resultMessage)
			}else{
				message.success(task.resultMessage)
			}
		break;
		case TaskType.Single:
			reserveTask()
		break;
	
		default:
			break;
	}

//   if(planBody.task_type ==TaskType.Immediate ){
// 	  let task =  await startTask(wayline.value.id)  
// 	  if(task.resultCode != '200' && task.resultMessage){
// 		message.error(task.resultMessage)
// 	  }else{
// 		message.success(task.resultMessage)
// 	  }
//   }else{
// 	console.info("planBody.select_execute_time",planBody.select_execute_time)
//   }
  disabled.value = false;

};

function selectDevice() {
	store.commit('SET_SELECT_DEVICE_INFO_DIALOG', false);
	drawerVisible.value = true;
	routeName.value = 'DockPanel';
}

function selectRoute() {
	store.commit('SET_SELECT_WAYLINE_INFO_DIALOG', false);
	drawerVisible.value = true;
	routeName.value = 'waylineFuya';
}

function closePlan() {
	root.$router.push('/' + ERouterName.TASK);
}

function closePanel() {
	drawerVisible.value = false;
	routeName.value = '';
}
watch(
	() => store.state.modalData.wayline,
	(data) => {
		if (data && (~currDockMode.value.indexOf("fuya"))) {
			closePanel();
		}
	},
	{
		deep: true,
		immediate: true,
	}
);

watch(
	() => store.state.modalData.device,
	(data) => {
		if (data && (~currDockMode.value.indexOf("fuya"))) {
			closePanel();
		}
	},
	{
		deep: true,
		immediate: true,
	}
);


</script>
<style  lang='scss' scoped>
.fuya {
  margin: 10px;
}
</style>  


<style scoped lang="scss">
::v-deep .ant-form-item-label > label {
	color: #fff;
}

::v-deep .ant-picker-focused {
	.ant-picker-input {
		input {
			color: #fff;
		}
	}
}

::v-deep .ant-form-item-control-input {
	.ant-picker-input {
		input {
			color: #fff;
		}
	}
}


.header{
	:deep(.ant-menu){
		background-color: #232323;
		color: #fff;
	}
	:deep(.ant-menu-horizontal){
		border-bottom: none;
	}
}


.select_guize {
	color: #1890ff;
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
}
.create-plan-wrapper {
	background-color: #232323;
	color: fff;
	padding-bottom: 0;
	height: 100vh;
	display: flex;
	flex-direction: column;

	.header {
		height: 52px;
		border-bottom: 1px solid #4f4f4f;
		font-weight: 700;
		font-size: 16px;
		padding-left: 10px;
		display: flex;
		align-items: center;
	}

	.content {
		height: calc(100% - 70px);
		overflow-y: auto;

		form {
			margin: 10px;
		}

		form label,
		input {
			background-color: #232323;
			color: #fff;
		}

		.ant-input-suffix {
			color: #fff;
		}

		.plan-timer-form-item {
			// flex-direction: column;

			.ant-radio-button-wrapper {
				background-color: #232323;
				color: #fff;
				margin-right: 5px;
				margin-bottom: 5px;
				&.ant-radio-button-wrapper-checked {
					background-color: #1890ff;
				}
			}
		}
	}

	.footer {
		display: flex;
		padding: 10px 0;
		button {
			width: 45%;
			color: #fff;
			border: 0;
		}
	}
}

.wayline-panel {
	background: #3c3c3c;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	height: 90px;
	width: 95%;
	font-size: 13px;
	border-radius: 2px;
	cursor: pointer;
	.title {
		display: flex;
		color: white;
		flex-direction: row;
		align-items: center;
		height: 30px;
		font-weight: bold;
		margin: 0px 10px 0 10px;
	}
}
.chongfugongshi {
	// display: flex !important;
	display: none !important;
}
.panel {
	background: #3c3c3c;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	height: 70px;
	width: 95%;
	font-size: 13px;
	border-radius: 2px;
	cursor: pointer;
	.title {
		display: flex;
		color: white;
		flex-direction: row;
		align-items: center;
		height: 30px;
		font-weight: bold;
		margin: 0px 10px 0 10px;
	}
}
.icon_data {
	position: fixed;
	top: 0px;
	left: 200px;
}
::v-deep .ant-radio-group {
	width: 280px !important;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

::v-deep .ant-picker-suffix {
	color: #fff;
}
</style>

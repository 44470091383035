<template>
	<div class="flex-column flex-justify-center flex-align-center" ref="livestream" style="position: relative; height: 100%;overflow: hidden;" :id="`livestream_${sn}`">
		<div class="player-content">
			<a-spin class="spinDisplay" size="large" v-if="loading" />
			<!-- <div id="player" v-else></div> -->
			<div class="player" :id="'player_' + sn" :class="'player' + comeFrom + sn" v-show="showVideo">
				<!-- <jessibuca
					ref="jessibucaVideo"
					style="width: 100%; height: 100%"
					v-if="showVideo"
					:videoUrl="flvUrl"
					@onresize="onresize"
				></jessibuca> -->
			</div>
			<div :id="'currData' + sn" v-show="showVideo && hasResize" class="currData">
				<div class="vedio-info" :style="{ fontSize: fontSize + 'px' }">
					<span>{{ user_nickname }}</span>
				</div>
				<div class="vedio-curr-date" :style="{ fontSize: fontSize + 'px' }">
					{{ curr }}

					<span v-if="!isShowData && props.showClose" title="关闭" style="color: #fff" href="#" @click="emits('closeVideo', props.sn)"
						><CloseOutlined
					/></span>
				</div>
			</div>
			<!-- <div class="quanping">
				<button class="fullscreen-button" @click="toggleFullScreen()">全屏</button>

			</div> -->
			<div class="noPlayer" v-show="!loading && !showVideo">当前直播已关闭！</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { message } from 'ant-design-vue';
import { onMounted, ref, defineEmits, onUnmounted, defineExpose } from 'vue';
// import { getLiveCapacity } from '/@/api/manage';
import { getLiveCapacityBySn, startLivestream } from '/@/api/manage';
import { getRoot } from '/@/root';
import jswebrtc from '/@/vendors/jswebrtc.js';
import FlvExtend from 'flv-extend';
// import jessibuca from './live/jessibuca.vue';
import { CloseOutlined } from '@ant-design/icons-vue';
interface Props {
	sn: string;
	showVideo: boolean;
	curr: string;
	user_nickname: string;
	showClose?: boolean;
	comeFrom?: boolean;
}
interface DomByData {
	sn: string;
	roomID: number;
	url: string;
	type: string;
	uid?: string;
	token?: string;
	camersName: string;
	camersNameCN?: string;
	videoId: string;
	video_quality: number;
	domElement?: string;
}
const root = getRoot();
// 推流类型
const streamType = 'rtmp';
const flvUrl = ref('');
const videoType = 'rtmp';
const videoTypeByKey = {
	rtmp: '1',
};
const droneCameraBtn = ref<any>([]);
const loading = ref<boolean>(true);

const props = defineProps<Props>();
const videowebrtc = ref(null);
const emits = defineEmits(['closeVideo']);
const jessibucaVideo = ref();

// 获取相机信息 有几个摄像头
const getLiveCapacityDatas = async () => {
	droneCameraBtn.value = [];
	const devices = await getLiveCapacityBySn(props.sn, {});

	if (devices.code === 0) {
		// 获取在线的更多数据
		const moreInfo = devices.data;
		if (moreInfo.length == 0) {
			emits('closeVideo', props.sn);
			return (loading.value = false);
		}
		// 循环在线的数据
		/**
		 * mo 数据
		 * sn 设备对应的编号 // 机场的编号 || 飞机的编号
		 * name 设备对应的名称
		 * cameras_list 相机list
		 *
		 */
		for (let mo of moreInfo) {
			if (mo.cameras_list) {
				/**
				 * cameras_list 数据
				 * id 相机的id
				 * index 相机的索引
				 * name 相机的名字
				 * videos_list 相机内部视频的数组
				 *
				 */
				for (let cl of mo.cameras_list) {
					if (cl.videos_list) {
						/**
						 * videos_list 视频内部数据
						 * id 视频的id
						 * index 视频的索引
						 * room_id 视频对应的房间号
						 * type 类型
						 * uid 用户的id
						 */
						for (let y of cl.videos_list) {
							y.clname = cl.name?.split(' ')[0];
							y.clIndex = cl.index;
							y.vlIndex = y.index;
							y.name = mo.name;
							y.sn = mo.sn;
							y.camerasID = cl.id;
							y.active = false;
							// !droneCameraBtn.value.find((element: any) => element.camerasID === y.camerasID) && droneCameraBtn.value.push(y);
							y.type === 'wide' && droneCameraBtn.value.push(y);
						}
					} else {
						message.error('未查询到相机中的视频信息');
					}
				}
			} else {
				droneCameraBtn.value = [];
				// message.error('未查询到当前相机信息');
			}
		}
	} else {
		emits('closeVideo', props.sn);
		loading.value = false;
	}
};
watch(
	() => props.showVideo,
	async (newValue, oldValue) => {
		// 如果不是断开重连不重新查询
		if (oldValue) return;
		// 如果飞机断联 删除视频播放节点
		if (!newValue) return deleteChilds;
		await getLiveCapacityDatas();
		await nextTick();
		if (droneCameraBtn.value.length > 0) {
			getLiveData(droneCameraBtn.value[0]);
		}
	},
	{
		immediate: true,
	}
);

// const toggleFullScreen = () => {
// 	// const video = $refs.videoPlayer;

// 	const video =  document.getElementById(`player_video_${props.sn}`)
// 	if (video && !document.fullscreenElement) {
// 		video.requestFullscreen();
// 		setTimeout(()=>{
// 			console.info("document.fullscreenElement",document.fullscreenElement)
// 		},500)
// 	} else {
// 		if (document.exitFullscreen) {
// 			document.exitFullscreen();
// 		}
// 	}
// }
//清空父元素下所有子元素
function deleteChilds(ele: Element) {
	if (!ele) {
		return;
	}
	var ul = ele; // 获取父级DOM
	// 判断是否包含子元素
	if (ul.hasChildNodes()) {
		var len = ul.childNodes.length; // 子元素的个数
		for (var i = 0; i < len; i++) {
			// 遍历
			ul.removeChild(ul.childNodes[0]); // 从第一个元素开始删除
		}
	}
}

const isShowData = ref(false);
//解决首次加载会字体一下子变化的变量
const hasResize = ref(false);
const fontSize = ref(12);
const onresize = () => {
	hasResize.value = false;
	const el = document.getElementsByClassName(`player${props.comeFrom + props.sn}`)[0] as Element;
	fontSize.value = parseInt(el.clientHeight / 100) * 5 < 12 ? 12 : parseInt(el.clientHeight / 100) * 5;
	hasResize.value = true;
};

const playerObj = ref();
const rtmpVideoData = async (uid: string, url: string, domName: string) => {
	// 查询当前dom 中的video 标签
	const remotePlayerContainer: any = document.getElementById(domName);
	//判断是webrtc的视频流
	if (url.includes('webrtc')) {
		// 创建jsw的数据
		remotePlayerContainer.src = url;
		new jswebrtc.Player(url, {
			video: remotePlayerContainer,
			hasAudio: false, // 是否包含音频
			autoplay: true,
			onPlay: (obj: any) => {
				console.log('createRtmpVideo ==> start play livestream');
				// liveByData.data[uid] = {
				//   resUrl:url,
				//   uid,
				//   status:"play"
				// }
			},
		});
	} else {
		/* flvUrl.value = url;
		jessibucaVideo.value.init(url); */
		const flv = new FlvExtend({
			element: remotePlayerContainer, // *必传
			frameTracking: true, // 开启追帧设置
			updateOnStart: true, // 点击播放后更新视频
			updateOnFocus: true, // 获得焦点后更新视频
			reconnect: true, // 开启断流重连
			reconnectInterval: 2000, // 断流重连间隔
		});

		// 调用 init 方法初始化视频
		const player = flv.init(
			{
				type: 'flv',
				url: url,
				isLive: true,
			},
			{
				// // autoCleanupSourceBuffer: true, //对SourceBuffer进行自动清理缓存
				// autoCleanupMaxBackwardDuration: 12, //    当向后缓冲区持续时间超过此值（以秒为单位）时，请对SourceBuffer进行自动清理
				// autoCleanupMinBackwardDuration: 60, //指示进行自动清除时为反向缓冲区保留的持续时间（以秒为单位）。
				enableStashBuffer: false, // 如果您需要实时（最小延迟）来进行实时流播放，则设置为false
				stashInitialSize: 128, // 减少首帧显示等待时长
			}
		);
		playerObj.value = player
		setTimeout(() => {
			remotePlayerContainer.addEventListener('click', (event) => {
				event.preventDefault();
			});
			let pla = document.getElementById(`livestream_${props.sn}`) as Element;
			// 添加双击全屏放大功能
			remotePlayerContainer.addEventListener('dblclick', () => {
				console.log("🚀 ~ setTimeout ~ player_:")
				if (!document.fullscreenElement) {
					if (pla.requestFullscreen) {
						pla.requestFullscreen();
					} else if (pla.mozRequestFullScreen) {
						// 兼容火狐浏览器
						pla.mozRequestFullScreen();
					} else if (pla.webkitRequestFullscreen) {
						// 兼容谷歌浏览器和Safari
						pla.webkitRequestFullscreen();
					}
					isShowData.value = true;
					onresize()

					// nextTick(() => {
					// 	let divs = document.getElementById(`currData`) as Element;
					// 	pla.appendChild(divs);
					// });
				} else {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					} else if (document.mozCancelFullScreen) {
						// 兼容火狐浏览器
						document.mozCancelFullScreen();
					} else if (document.webkitExitFullscreen) {
						// 兼容谷歌浏览器和Safari
						document.webkitExitFullscreen();
					}
					nextTick(() => {
						isShowData.value = false;
					});
				}
			});
			player.play();
			console.log('🚀 ~ setTimeout ~ payer:', player);
			onresize()
			remotePlayerContainer.addEventListener(
				'ended',
				function () {
					console.log('视频播放结束');
					emits('closeVideo', props.sn);
					player.close();
				},
				false
			);
		}, 200);
	}
};
// 创建rtmp 视频
const createRtmpVideo = async (uid: string, url: string) => {
	// 根据 roomID 创建 获取对应的dom
	let remotePlay;
	remotePlay = document.getElementById(`player_${props.sn}`) as Element;
	if (remotePlay) {
		deleteChilds(remotePlay);
	}

	loading.value = false;
	// 创建video 标签
	const link = document.createElement('video');
	link.controls = false;
	link.style.width = '100%';
	link.muted = true;
	// link.style.height = '100%';
	// 指定 dom 标签的id
	link.id = `player_video_${props.sn}`;
	// 将video加入到 dom中
	remotePlay?.appendChild(link);
	await nextTick(async () => {
		await rtmpVideoData(uid, url, `player_video_${props.sn}`);
		// videoEvent()
	});
};
// 准备推流，获取地址
const liveStart = async (data: DomByData, url_type: string = '0') => {
	return await startLivestream({
		url: data.url,
		video_id: data.videoId,
		url_type: url_type,
		video_quality: data.video_quality + '',
	});
};
// rtmp版本开启机场视频数据
const openLive = async (options: DomByData) => {
	// 开启视频
	let res = await liveStart(options, videoTypeByKey[videoType]);
	if (res && res.data && res.data.url) {
		sessionStorage.setItem('rtmpUrl', res.data.url);
		let resUrl = res.data.url;
		if (resUrl) {
			await createRtmpVideo(options.roomID + '', resUrl);
		}
	} else {
		message.error(`${options.videoId}视频开启失败`);
		emits('closeVideo', props.sn);
		loading.value = false;
	}
};
/**
 * @remark 根据vedios（也就是摄像头的id）的id生成视频流
 * @param vl 拼接出来摄像所需要的所有参数对象
 */
const getLiveVedioById = async (vl: any) => {
	vl.room_id = vl.id;
	let videoDataJson: DomByData = {
		type: vl.type,
		sn: vl.sn,
		roomID: vl.room_id,
		uid: vl.uid,
		camersName: vl.name,
		camersNameCN: '',
		url: `channel=${vl.room_id}&sn=${vl.sn}&uid=${vl.uid}`,
		// videoId: `${vl.sn}/${vl.clIndex}/${vl && vl.vlIndex ? vl.vlIndex : 'normal-0'}`,
		videoId: `${vl.sn}/${vl.clIndex}/normal-0`,
		video_quality: 3,
	};

	// await onStop(videoDataJson.videoId);
	// setTimeout(async () => {
	await openLive(videoDataJson);
	// }, 500);
};
// 获取视频
const getLiveData = async (info: any) => {
	// 获取最新视频列表
	// await getLiveCapacityDatas();
	/* droneCameraBtn.value.map((el: any) => {
		el.active = el.camerasID == info.camerasID ? true : false;
	}); */
	// 如果是以选中摄像头，仅请求相机列表，不重新加载视频
	// if (info.active) return;
	loading.value = true;
	// 选择点击的视频
	let currentVedio = droneCameraBtn.value.find((el: { camerasID: any }) => el.camerasID == info.camerasID);
	getLiveVedioById(currentVedio);
};
const livestream = ref(null);
let observer = null;

const handleResize = (entries) => {
	for (let entry of entries) {
		jessibucaVideo?.value.resize();
	}
};
onMounted(async () => {
	// await getLiveCapacityDatas();
	await nextTick();
	// if (droneCameraBtn.value.length > 0) {
	// 	getLiveData(droneCameraBtn.value[0]);
	// }
	// addFullEvent()
	// addEventListener('resize', jessibucaVideResize);
	/* observer = new ResizeObserver(handleResize);
	if (livestream.value) {
		observer.observe(livestream.value);
	} */
});

onUnmounted(() => {
	if (observer) {
		observer.disconnect();
	}
	if(playerObj.value){
		playerObj.value.close();
	}
});

defineExpose({ onresize });
// const addFullEvent = () => {
// 	document.addEventListener('fullscreenchange', handleFullscreenChange)

// }

/* const videoEvent = () => {
	const videohtml =  document.getElementById(`player_video_${props.sn}`)
	if(!videohtml){
		return
	}
	// 监听视频的暂停事件
	videohtml.addEventListener("pause",function(){
		videohtml.play()
	})
} */

// const handleFullscreenChange = () => {
// 	const videohtml =  document.getElementById(`player_video_${props.sn}`)
// 	debugger
// 	if(!videohtml){
// 		return
// 	}
// 	if (document.fullscreenElement === videohtml) {
// 		videohtml.removeAttribute("controls") // 隐藏控制条
// 	}
// }
</script>

<style lang="scss" scoped>
@import '/@/styles/index.scss';
.player-content {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	// max-height: 600px;
	overflow: hidden;
	position: relative;
	.spinDisplay {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.5);
	}
	> .quanping {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		color: greenyellow;
		z-index: 1000;
	}
	> .player {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
	.noPlayer {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 24px;
	}
}
/* 隐藏video 全屏按钮 */
/* .video::-webkit-media-controls-fullscreen-button {
	display: none;
} */
/* 隐藏video 播放按钮 */
.video::-webkit-media-controls-play-button {
	display: none;
}
/* 隐藏video 进度条 */
.video::-webkit-media-controls-timeline {
	display: none;
}
/* 隐藏video 观看的当前时间 */
.video::-webkit-media-controls-current-time-display {
	display: none;
}
/* 隐藏video 剩余时间 */
.video::-webkit-media-controls-time-remaining-display {
	display: none;
}
/* 隐藏video 音量按钮 */
.video::-webkit-media-controls-mute-button {
	display: none;
}
.video::-webkit-media-controls-toggle-closed-captions-button {
	display: none;
}
/* 隐藏video 音量的控制条 */
.video::-webkit-media-controls-volume-slider {
	display: none;
}
/* 隐藏video 所有控件 */
.video::-webkit-media-controls-enclosure {
	display: none;
}
.vedio-info {
	position: absolute;
	top: 10px;
	left: 10px;
}
.vedio-curr-date {
	position: absolute;
	top: 10px;
	right: 10px;

	& > span {
		display: inline-flex;
		width: 30px;
		height: 30px;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.2);
	}
}
</style>

<style>
/* video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

:not(:root):fullscreen::-webkit-media-controls-start-playback-button {
  display: none !important;
} */
</style>

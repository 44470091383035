/*
 * @Date: 2024-01-19 09:41:38
 * @LastEditors: jiangchao
 * @LastEditTime: 2024-04-23 15:32:59
 * @Description: 文件注释
 * @Author: jiangchao
 * @FilePath: \redfly-web\src\directives\drag-directive.ts
 */
export default {
	install: (app) => {
		app.directive('drag-directive', {
			mounted(el, binding) {
				const dragable = {};
				// 获取绑定鼠标移动事件的元素
				const header = el.getElementsByClassName('drag-title')[0];
				// 创建 refs 来存储初始坐标和鼠标点击时的位置
				const startX = ref(0);
				const startY = ref(0);
				const initialX = ref(0);
				const initialY = ref(0);
				const width = ref(0);
				const height = ref(0);
				if(header){
					header.style.cursor = 'move';
				}

				// 指令绑定值可能包含配置参数，比如限制拖动范围等
				const { container, range } = binding.value || {};

				// 监听mousedown事件开始拖动
				const handleMouseDown = (e) => {
					// 阻止冒泡和默认行为
					e.stopPropagation();
					e.preventDefault();

					// 记录初始位置
					const cstyle = window.getComputedStyle(el);
					initialX.value = Number.parseInt(cstyle.left);
					initialY.value = Number.parseFloat(cstyle.top);

					width.value = Number.parseInt(cstyle.width);
					console.log('width.value', width.value);
					height.value = Number.parseInt(cstyle.height);

					startX.value = e.clientX;
					startY.value = e.clientY;

					// 添加mousemove和mouseup的监听器
					document.addEventListener('mousemove', handleMouseMove);
					document.addEventListener('mouseup', handleMouseUp);
				};

				const handleMouseMove = (e) => {
					e.preventDefault()
					// 计算新的位置
					let newX = initialX.value + (e.clientX - startX.value);
					let newY = initialY.value + (e.clientY - startY.value);

					// 如果有容器范围限制，则调整位置
					/* if (range) {
						const containerRect = el.parentNode.getBoundingClientRect();
						// 父容器作为移动范围
						newX = Math.max(range.minX, Math.min(newX, containerRect.width -15 - width.value));
						newY = Math.max(range.minY, Math.min(newY, containerRect.height - height.value));
					} */

					// 更新元素的位置
					el.style.left = `${newX}px`;
					el.style.top = `${newY}px`;
				};

				const handleMouseUp = () => {
					// 移除mousemove和mouseup的监听器
					document.removeEventListener('mousemove', handleMouseMove);
					document.removeEventListener('mouseup', handleMouseUp);
				};
				
				header && header.addEventListener('mousedown', handleMouseDown);
			},
		});
	},
};

/*
 * @Author: gejia 905034981@qq.com
 * @Date: 2024-01-09 11:15:56
 * @LastEditors: gejia 905034981@qq.com
 * @LastEditTime: 2024-01-13 15:19:48
 * @FilePath: \redfly-web\src\directives\drag-window.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { nextTick, App } from 'vue';

export default function useDragWindowDirective(app: App): void {
	app.directive('drag-window', async (el) => {
		await nextTick();
		const modal = el;
		const header = el.getElementsByClassName('drag-title')[0];
		let left = 0;
		let top = 0;
		header.style.cursor = 'move';
		top = top || modal.offsetTop;

		header.onpointerdown = (e: { clientX: number; clientY: number; pointerId: number }) => {
			const startX = e.clientX;
			const startY = e.clientY;
			header.left = header.offsetLeft;
			header.top = header.offsetTop;
			header.setPointerCapture(e.pointerId);

			el.onpointermove = (event: { clientX: number; clientY: number }) => {
				const endX = event.clientX;
				const endY = event.clientY;
				modal.left = header.left + (endX - startX) + left;
				modal.top = header.top + (endY - startY) + top;
				modal.style.left = modal.left + 'px';
				modal.style.top = modal.top + 'px';
			};
			el.onpointerup = () => {
				left = modal.left || 0;
				top = modal.top || 0;
				el.onpointermove = null;
				el.onpointerup = null;
				header.releasePointerCapture(e.pointerId);
			};
		};
	});
}

// 支持上下左右四边拖动。若内部元素太贴边，不易触发拖动事件，最好在拖动一边加边框，使鼠标容易选中。
// 使用方式：
// <div v-resizable="'right, bottom'">

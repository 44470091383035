<template>
	<div class="create-plan-wrapper" ref="createCreatives">
		<a-modal
			v-model:visible="cornvisible"
			:getContainer="() => createCreatives"
			@cancel="onCancel"
			@ok="onConfirm"
			style="background: #1c1c1c"
			title="添加配置"
		>
			<!-- <vue3-cron v-model="iconValue" locale="cn" /> -->
			<cron v-model="iconValue" locale="cn"></cron>
		</a-modal>
		<div class="header">
			<a-menu v-model:selectedKeys="current" mode="horizontal">
				<a-menu-item key="shuili"> 新建机场计划 </a-menu-item>
				<a-menu-item key="fuya" v-if="showFuya"> 新建复亚机场计划 </a-menu-item>
			</a-menu>
		</div>
		<template v-if="~current.indexOf('shuili')">
			<div class="content">
				<a-form ref="valueRef" layout="horizontal" :hideRequiredMark="true" :rules="rules" :model="planBody">
					<a-form-item label="计划名称" name="name" :labelCol="{ span: 24 }">
						<a-input style="background: black" placeholder="请输入计划名称" v-model:value="planBody.name" />
					</a-form-item>
					<!-- 航线 -->
					<a-form-item label="选择航线" :wrapperCol="{ offset: 7 }" name="file_id">
						<router-link :to="{ name: 'select-plan' }" @click="selectRoute"> 请选择航线 </router-link>
					</a-form-item>
					<a-form-item v-if="planBody.file_id" style="margin-top: -15px">
						<div class="wayline-panel" style="padding-top: 5px">
							<div class="title">
								<a-tooltip :title="wayline.name">
									<div class="pr10" style="width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
										{{ wayline.name }}
									</div>
								</a-tooltip>
								<div class="ml10"><UserOutlined /></div>
								<a-tooltip :title="wayline.user_name">
									<div class="ml5 pr10" style="width: 80px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
										{{ wayline.user_name }}
									</div>
								</a-tooltip>
							</div>
							<div class="ml10 mt5" style="color: hsla(0, 0%, 100%, 0.65)">
								<span><RocketOutlined /></span>
								<span class="ml5">{{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(wayline.drone_model_key)] }}</span>
								<span class="ml10"><CameraFilled style="border-top: 1px solid; padding-top: -3px" /></span>
								<span class="ml5" v-for="payload in wayline.payload_model_keys" :key="payload.id">
									{{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(payload)] }}
								</span>
							</div>
							<div class="mt5 ml10" style="color: hsla(0, 0%, 100%, 0.35)">
								<span class="mr10">Update at {{ new Date(wayline.update_time).toLocaleString() }}</span>
							</div>
						</div>
					</a-form-item>
					<!-- 设备 -->
					<a-form-item label="机场设备" :wrapperCol="{ offset: 7 }" v-model:value="planBody.dock_sn" name="dock_sn">
						<router-link :to="{ name: 'select-plan' }" @click="selectDevice">请选择设备</router-link>
					</a-form-item>
					<a-form-item v-if="planBody.dock_sn" style="margin-top: -15px">
						<div class="panel" style="padding-top: 5px">
							<div class="title">
								<a-tooltip :title="dock.nickname">
									<div class="pr10" style="width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
										{{ dock.nickname }}
									</div>
								</a-tooltip>
							</div>
							<div class="ml10 mt5" style="color: hsla(0, 0%, 100%, 0.65)">
								<span><RocketOutlined /></span>
								<span class="ml5">{{ dock.children?.nickname }}</span>
							</div>
						</div>
					</a-form-item>
					<!-- 任务类型 -->
					<a-form-item label="计划时间类型" :labelCol="{ span: 24 }" class="plan-timer-form-item">
						<div style="white-space: nowrap">
							<a-radio-group v-model:value="planBody.task_type" button-style="solid">
								<a-radio-button :value="TaskType.Immediate">立即</a-radio-button>
								<a-radio-button :value="TaskType.Single">单次定时</a-radio-button>
								<a-radio-button :value="TaskType.RepeatTimed">重复定时</a-radio-button>
							</a-radio-group>
						</div>
					</a-form-item>
					<!-- 执行时间 -->
					<a-form-item label="执行时间" v-if="planBody.task_type === TaskType.Single" name="select_execute_time">
						<a-date-picker
							v-model:value="planBody.select_execute_time"
							@change="onChange"
							@ok="onOk"
							format="YYYY-MM-DD HH:mm:ss"
							show-time
							placeholder="选择时间"
							style="width: 200px; background-color: #232323"
						/>
					</a-form-item>
					<a-form-item label="定时规则" :wrapperCol="{ offset: 7 }" v-if="planBody.task_type === TaskType.RepeatTimed">
						<div class="select_guize" @click="iconButton">{{ iconValue === '* * * * * ?' ? '请选择定时规则' : '已选择定时规则' }}</div>
					</a-form-item>
					<a-form-item
						class="chongfugongshi"
						label="请选择重复的公式："
						v-if="planBody.task_type === TaskType.RepeatTimed"
						:labelCol="{ span: 24 }"
					>
						<a-input-group compact>
							<a-input v-model:value="iconValue" />
							<a-tooltip title="请输入icon表达式">
								<a-button @click="iconButton">
									<template #icon><CopyOutlined /></template>
								</a-button>
							</a-tooltip>
						</a-input-group>
					</a-form-item>
					<a-form-item label="相对机场返航高度(m)" :labelCol="{ span: 24 }" name="rth_altitude">
						<a-input v-model:value="planBody.rth_altitude" style="background: black !important"> </a-input>
					</a-form-item>
					<a-form-item
						label="航线飞行中失联（安全策略）"
						:labelCol="{ span: 24 }"
						name="out_of_control_action"
						class="plan-timer-form-item"
					>
						<div style="white-space: nowrap">
							<a-radio-group v-model:value="planBody.out_of_control_action" button-style="solid">
								<a-radio-button v-for="action in OutOfControlActionOptions" :value="action.value" :key="action.value">
									{{ action.label }}
								</a-radio-button>
							</a-radio-group>
						</div>
					</a-form-item>

					<a-form-item label="是否启用Ai" :labelCol="{ span: 24 }" name="out_of_control_action" v-if="AiAuthor">
						<div style="white-space: nowrap">
							<a-switch checked-children="开" un-checked-children="关" v-model:checked="isOpenAi" />
						</div>
					</a-form-item>
					<a-form-item label="是否启用红外" :labelCol="{ span: 24 }" name="out_of_control_action">
						<div style="white-space: nowrap">
							<a-switch checked-children="开启红外" un-checked-children="关闭红外" v-model:checked="openIR" />
						</div>
					</a-form-item>

					<a-form-item
						label="Ai类型选择"
						:labelCol="{ span: 24 }"
						name="out_of_control_action"
						v-if="AiAuthor"
						class="plan-timer-form-item"
					>
						<div style="white-space: nowrap">
							<a-radio-group v-model:value="lineTypeData" button-style="solid">
								<a-radio-button v-for="action of lineType" :value="action.value" :key="action.value">
									{{ action.label }}
								</a-radio-button>
							</a-radio-group>
						</div>
					</a-form-item>

					<a-form-item label="选择视频清晰度" :labelCol="{ span: 24 }" name="out_of_control_action" class="plan-timer-form-item">
						<div style="white-space: nowrap">
							<a-radio-group v-model:value="videoQuality" button-style="solid">
								<a-radio-button v-for="action of VideoQualityData" :value="action.value" :key="action.value">
									{{ action.label }}
								</a-radio-button>
							</a-radio-group>
						</div>
					</a-form-item>

					<a-form-item>
						<div class="footer">
							<a-button class="mr10" style="background: #3c3c3c" @click="closePlan">取消 </a-button>
							<a-button type="primary" @click="onSubmit" :disabled="disabled">确定 </a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
		</template>
		<template v-else>
			<div class="content">
				<create-fu-ya-plan> </create-fu-ya-plan>
			</div>
		</template>
	</div>
	<div
		v-if="drawerVisible"
		style="position: absolute; left: 330px; width: 280px; height: 100vh; float: right; top: 0; z-index: 1000; color: white; background: #282828"
	>
		<div style="height: 100%">
			<router-view :name="routeName" />
		</div>
		<div style="position: absolute; top: 15px; right: 10px">
			<a style="color: white" @click="closePanel"><CloseOutlined /></a>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref, toRaw, UnwrapRef } from 'vue';
import { CloseOutlined, RocketOutlined, CameraFilled, UserOutlined, CopyOutlined } from '@ant-design/icons-vue';
import { ELocalStorageKey, ERouterName } from '/@/types';
import { useMyStore } from '/@/store';
import { WaylineType, WaylineFile } from '/@/types/wayline';
import { Device, EDeviceType } from '/@/types/device';
import { CreatePlanBody, createPlan } from '/@/api/wayline';
import { getRoot } from '/@/root';
import { TaskType, OutOfControlActionOptions, OutOfControlAction } from '/@/types/task';
import moment, { Moment } from 'moment';
import { RuleObject } from 'ant-design-vue/es/form/interface';
import { getAiLiveData } from '/@/api/ai';
import CreateFuYaPlan from '/@/components/task/CreateFuYaPlan.vue';
import { useUserAuthor } from '/@/hooks/use-g-author';

const root = getRoot();
const store = useMyStore();
const isOpenAi = ref(false);
const showFuya = computed(() => {
	return ~location.host.indexOf('32.121.75');
});
const videoQuality = ref('0');

const VideoQualityData = ref([
	{
		value: '0',
		label: '自适应',
	},
	{
		value: '1',
		label: '流畅',
	},
	{
		value: '2',
		label: '标清',
	},
	{
		value: '3',
		label: '高清',
	},
	{
		value: '4',
		label: '超清',
	},
]);

const { getAiAuthor } = useUserAuthor();

const AiAuthor = ref<boolean>(getAiAuthor());

// 判断当前是否是定时任务

const current = ref<string[]>(['shuili']);

const onCancel = () => {
	cornvisible.value = false;
};
const onConfirm = () => {
	cornvisible.value = false;
};

const iconValue = ref('* * * * * ?');
const cornvisible = ref(false);

let createCreatives = ref(null);
const iconButton = () => {
	cornvisible.value = true;
};

const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!;

const wayline = computed<WaylineFile>(() => {
	return store.state.waylineInfo;
});

const dock = computed<Device>(() => {
	return store.state.dockInfo;
});

const repeatMode = computed(() => {
	return planBody.task_type == TaskType.RepeatTimed ? 1 : 0;
});

const disabled = ref(false);
const openIR = ref(false);
const onOk = (value: any) => {
	console.info('onOk====>', value);
};
let task_days: any = ref('');
let task_periods: any = ref('');
const onChange = (value: any, dateString: string) => {
	console.info('onChange====>', value, dateString);
	task_days.value = [moment(dateString).unix()];
	task_periods.value = [task_days.value];
};
const routeName = ref('');
const planBody = reactive({
	name: '',
	file_id: computed(() => store.state.waylineInfo.id),
	dock_sn: computed(() => store.state.dockInfo.device_sn),
	task_type: TaskType.Immediate,
	taskDays: JSON.stringify([moment().valueOf() + '', moment().valueOf() + '']),
	taskPeriods: [[moment().add(1, 'day').valueOf()], [moment().add(2, 'day').valueOf()]],
	select_execute_time: undefined as Moment | undefined,
	rth_altitude: '',
	out_of_control_action: OutOfControlAction.ReturnToHome,
});

const drawerVisible = ref(false);
const valueRef = ref();
const rules = {
	name: [
		{ required: true, message: '请输入计划名称。' },
		{ max: 20, message: '长度为1 ~ 20', trigger: 'blur' },
	],
	file_id: [{ required: true, message: '选择路线' }],
	dock_sn: [{ required: true, message: '选择设备' }],
	select_execute_time: [{ required: true, message: '选择开始时间' }],
	rth_altitude: [
		{
			validator: async (rule: RuleObject, value: string) => {
				if (!/^[0-9]{1,}$/.test(value)) {
					throw new Error('RTH高度相对需要数');
				}
			},
		},
	],
	out_of_control_action: [{ required: true, message: '选择丢失动作' }],
};

const lineType = ref([]);
const lineTypeData = ref(2);

let getListDatas = async () => {
	let aiLiveData: any = await getAiLiveData();
	//
	if (aiLiveData.status == '200') {
		const aiLive = aiLiveData.data;
		if (aiLive.code == '0') {
			lineType.value = aiLive.data;
			lineTypeData.value = aiLive.data[0].value;
		}
	}
};

onMounted(() => {
	getListDatas();
});

watch(
	() => store.state.modalData.wayline,
	(data) => {
		if (data && ~current.value.indexOf('shuili')) {
			closePanel();
		}
	},
	{
		deep: true,
		immediate: true,
	}
);

watch(
	() => store.state.modalData.device,
	(data) => {
		if (data && ~current.value.indexOf('shuili')) {
			closePanel();
		}
	},
	{
		deep: true,
		immediate: true,
	}
);

provide('currDockMode', current);

function onSubmit() {
	valueRef.value
		.validate()
		.then(async () => {
			disabled.value = true;

			const createPlanBody = {
				...planBody,
				aiRecognition: isOpenAi.value, // 是否开启ai
				recognitionType: lineTypeData.value, // ai参数
				// createPlanBody:openIR?""
				// video_type:openIR.value?"ir":"wide",
				repeatMode: repeatMode.value,
				cron: iconValue.value,
				cameraMode: openIR.value ? 'ir' : 'normal',
				sharpness: videoQuality.value, // 视频清晰度
			} as unknown as CreatePlanBody;
			if (planBody.select_execute_time) {
				createPlanBody.task_days = task_days.value;
				createPlanBody.task_periods = task_periods.value;
			}
			createPlanBody.rth_altitude = Number(createPlanBody.rth_altitude);
			if (wayline.value && wayline.value.template_types && wayline.value.template_types.length > 0) {
				createPlanBody.wayline_type = wayline.value.template_types[0];
			}
			// console.log('planBody', createPlanBody)
			createPlan(workspaceId, createPlanBody)
				.then((res) => {
					setTimeout(() => {
						disabled.value = false;
					}, 1500);
				})
				.finally(() => {
					closePlan();
				});
		})
		.catch((e: any) => {
			console.log('validate err', e);
		});
}

function closePlan() {
	root.$router.push('/' + ERouterName.TASK);
}

function closePanel() {
	drawerVisible.value = false;
	routeName.value = '';
}

function selectRoute() {
	store.commit('SET_SELECT_WAYLINE_INFO_DIALOG', false);
	drawerVisible.value = true;
	routeName.value = 'WaylinePanel';
}

function selectDevice() {
	store.commit('SET_SELECT_DEVICE_INFO_DIALOG', false);
	drawerVisible.value = true;
	routeName.value = 'DockPanel';
}
</script>

<style scoped lang="scss">
::v-deep .ant-form-item-label > label {
	color: #fff;
}

::v-deep .ant-picker-focused {
	.ant-picker-input {
		input {
			color: #fff;
		}
	}
}

::v-deep .ant-form-item-control-input {
	.ant-picker-input {
		input {
			color: #fff;
		}
	}
}

.header {
	:deep(.ant-menu) {
		background-color: #232323;
		color: #fff;
	}
	:deep(.ant-menu-horizontal) {
		border-bottom: none;
	}
}

.select_guize {
	color: #1890ff;
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
}
.create-plan-wrapper {
	background-color: #232323;
	color: fff;
	padding-bottom: 0;
	height: 100vh;
	display: flex;
	flex-direction: column;

	.header {
		height: 52px;
		border-bottom: 1px solid #4f4f4f;
		font-weight: 700;
		font-size: 16px;
		padding-left: 10px;
		display: flex;
		align-items: center;
	}

	.content {
		height: calc(100% - 70px);
		overflow-y: auto;

		form {
			margin: 10px;
		}

		form label,
		input {
			background-color: #232323;
			color: #fff;
		}

		.ant-input-suffix {
			color: #fff;
		}

		.plan-timer-form-item {
			// flex-direction: column;

			.ant-radio-button-wrapper {
				background-color: #232323;
				color: #fff;
				margin-right: 5px;
				margin-bottom: 5px;
				&.ant-radio-button-wrapper-checked {
					background-color: #1890ff;
				}
			}
		}
	}

	.footer {
		display: flex;
		padding: 10px 0;
		button {
			width: 45%;
			color: #fff;
			border: 0;
		}
	}
}

.wayline-panel {
	background: #3c3c3c;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	height: 90px;
	width: 95%;
	font-size: 13px;
	border-radius: 2px;
	cursor: pointer;
	.title {
		display: flex;
		color: white;
		flex-direction: row;
		align-items: center;
		height: 30px;
		font-weight: bold;
		margin: 0px 10px 0 10px;
	}
}
.chongfugongshi {
	// display: flex !important;
	display: none !important;
}
.panel {
	background: #3c3c3c;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	height: 70px;
	width: 95%;
	font-size: 13px;
	border-radius: 2px;
	cursor: pointer;
	.title {
		display: flex;
		color: white;
		flex-direction: row;
		align-items: center;
		height: 30px;
		font-weight: bold;
		margin: 0px 10px 0 10px;
	}
}
.icon_data {
	position: fixed;
	top: 0px;
	left: 200px;
}
::v-deep .ant-radio-group {
	width: 280px !important;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

::v-deep .ant-picker-suffix {
	color: #fff;
}
</style>
